import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import './index.scss'
import { onErrorImage } from '../../utilities/event'

interface newListType {
   title: string
   description: string
   publisher: string
   link: string
   imageLink: string
}

const HibraryNewSection = () => {
   const [newsList, setNewsList] = useState<newListType[]>([
      {
         title: `โหลดเลย! แอปห้องสมุดเพื่อคนกรุง “BKK X Hibrary” อีบุ๊กฟรี อยู่เขตไหน อ่านได้ทุกที่`,
         publisher: 'สำนักงานประชาสัมพันธ์ กรุงเทพมหานคร',
         description: `BKK X Hibrary อีบุ๊กฟรี อยู่เขตไหน อ่านได้ทุกที่ เป็นโครงการความร่วมมือระหว่าง กรุงเทพมหานคร กับ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด เพื่อให้บริการห้องสมุดออนไลน์ มีนิตยสาร วารสาร หนังสือพิมพ์อิเล็กทรอนิกส์หลากหลายครอบคลุมทุกหมวดหมู่และถูกลิขสิทธิ์ ผู้ใช้บริการสามารถใช้บริการได้ทุกที่ทุกเวลา เพียงแค่ท่านอยู่ในพื้นที่กรุงเทพมหานคร`,
         link: 'https://pr-bangkok.com/?p=98745&fbclid=IwAR2jMNV609Aj43cslEKVeOCTAF7mEceWOGzFPFIbicgIrCbCPaVmOhH4qFk',
         imageLink: `https://pr-bangkok.com/wp-content/uploads/2023/03/S__8339509.jpg`
      },
      //? news-1
      {
         title: 'ไฮเท็คซ์ อินเตอร์แอคทีฟ เปิดตัว ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กรที่สมบูรณ์ที่สุดของไทย โดยมีผู้อำนวยการโรงเรียนวัฒนาวิทยาลัยร่วมถ่ายทอดการใช้งานจริง',
         publisher: 'Techhub',
         description:
            'ไฮเท็คซ์ อินเตอร์แอคทีฟ เปิดตัว ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กรที่สมบูรณ์ที่สุดของไทย โดยมีผู้อำนวยการโรงเรียนวัฒนาวิทยาลัยร่วมถ่ายทอดการใช้งานจริง',
         link: 'https://www.techhub.in.th/hibrary-e-library/',
         imageLink:
            'https://www.techhub.in.th/wp-content/uploads/2021/10/Photo-Caption-Hibrary-696x464.jpg'
      },
      //? news-2
      {
         title: 'ส่งมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ ช่วยสร้างการศึกษาอย่างเท่าเทียม ไร้พรมแดน',
         publisher: 'แนวหน้า',
         description:
            'ไฮเท็คซ์ อินเตอร์แอคทีฟ และ สยามจุลละมณฑล พัฒนาระบบห้องสมุดออนไลน์ ช่วยสร้างการศึกษาอย่างเท่าเทียม ล่าสุดต่อยอดความสำเร็จจากระบบห้องสมุดประชาชนออนไลน์ ส่งมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ ต้นแบบ แก่ สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย (กศน.) กระทรวงศึกษาธิการ ช่วยเพิ่มโอกาสทางการศึกษาและการเข้าถึงแหล่งความรู้แบบไร้พรมแดนด้วยสื่อดิจิตอลและระบบออนไลน์ พร้อมเปิดให้ประชาชนอ่านอีบุ๊กแบบถูกลิกสิทธิ์ ฟรี กว่า 1,500 รายการ',
         link: 'https://www.naewna.com/lady/637885',
         imageLink: 'https://static.naewna.com/uploads/news/source/637885.jpg'
      },
      //? news-3
      {
         title: 'รมช.ศธ.”กนกวรรณ” รับมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ มูลค่ากว่า 7 แสนบาท ให้บริการ e-Book ภายในศูนย์เรียนรู้วังจันทรเกษม ห้องสมุดต้นแบบ กศน.',
         publisher: 'Postoday',
         description:
            'นางกนกวรรณ วิลาวัลย์ รัฐมนตรีช่วยว่าการกระทรวงศึกษาธิการ เป็นประธานรับมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ ต้นแบบ จากนายอเล็กซองด์ ฮัมเมล กรรมการผู้จัดการใหญ่ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด และนายพสิษฐ์สัทคุณ จุลละมณฑล กรรมการและผู้อำนวยการฝ่ายเทคโนโลยีสารสนเทศ บริษัท สยามจุลละมณฑล จำกัด โดยมีนายกมล รอดคล้าย ที่ปรึกษา รมช.ศธ., นางสาวทรงศรี วิระรังษิยากรณ์, นายปรเมศวร์ ศิริรัตน์, นายภูมิภัทร เรืองแหล่ รองเลขาธิการ กศน. ร่วมเป็นสักขีพยาน และมีนางวัชรีภรณ์ โกสินเจริญชัย ผู้เชี่ยวชาญเฉพาะด้านพัฒนาเครือข่าย, นายประวิตร ประธรรมโย รองผู้อำนวยการสถาบันส่งเสริมและพัฒนานวัตกรรมการเรียนรู้ (สพร.) รักษาการผู้อำนวยการฯ ร่วมให้การต้อนรับ ณ ศูนย์การเรียนรู้วังจันทรเกษม กระทรวงศึกษาธิการ',
         link: 'https://www.posttoday.com/pr/675065',
         imageLink:
            'https://www.posttoday.com/_next/image?url=https%3A%2F%2Fimage.posttoday.com%2Fmedia%2Fcontent%2F2022%2F02%2F08%2F82E1D8275168F9C8B60E136570F5091B.jpg&w=1920&q=100'
      },
      //? news-4
      {
         title: `"ห้องสมุดออนไลน์ ไฮบรารี่" เปิดให้บริการประชาชน เสริมเรียนรู้ตลอดชีวิต`,
         publisher: 'กรุงเทพธุรกิจ',
         description: `กศน.ผนึกไฮเท็คซ์ อินเตอร์แอคทีฟ และสยามจุลละมณฑล เปิดห้องสมุดประชาชนออนไลน์ นวัตกรรมเครือข่าย "ห้องสมุดออนไลน์ ไฮบรารี่" หวังช่วยสร้างการศึกษาอย่างเท่าเทียม ไร้พรมแดน พร้อมเปิดให้อ่าน อีบุ๊ก ฟรี 1,500 รายการ`,
         link: 'https://www.bangkokbiznews.com/social/986294',
         imageLink:
            'https://image.bangkokbiznews.com/uploads/images/md/2022/02/D33Bpbyu12yFYbky9ZO7.webp?x-image-process=style/LG-webp'
      },
      //? news-5
      {
         title: 'โควิด-19 เทคโนโลยีดิจิทัล 2 ตัวเร่งภาครัฐและเอกชน ปรับตัวสู่ ดิจิทัล ทรานส์ฟอร์เมชั่น',
         publisher: 'Wealthplustoday',
         description:
            'ไฮเท็คซ์ อินเตอร์แอคทีฟ เปิดตัว ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กรที่สมบูรณ์ที่สุดของไทย โดยมีผู้อำนวยการโรงเรียนวัฒนาวิทยาลัยร่วมถ่ายทอดการใช้งานจริง',
         link: 'https://www.wealthplustoday.com/post/hitec201221',
         imageLink:
            'https://static.wixstatic.com/media/c5ac71_f94b29d45da14ca0a6c80372615eeb2d~mv2.png/v1/fill/w_740,h_493,al_c,q_90/c5ac71_f94b29d45da14ca0a6c80372615eeb2d~mv2.webpg'
      },
      //? news-6
      {
         title: 'เปิดให้อ่าน อีบุ๊ก ฟรี กว่า 1,000 หัวหนังสือ ผ่านแอปพลิเคชัน ไฮบรารี่',
         publisher: 'AppDisqus',
         description:
            'ระบบห้องสมุดองค์กรที่สมบูรณ์ที่สุดของไทย หวังคลายเครียดประชาชนช่วงน้ำท่วมและโควิด ที่ต้องมีการระวังตัว เว้นระยะห่าง การกักตัว หรือไม่สะดวกในการเดินทางออกนอกพื้นที่ พร้อมเสริมความรู้เด็กเรียนออนไลน์อย่างแท้จริง ลดอุปสรรคการอ่าน แก้ปัญหาคนไม่เข้าห้องสมุด สร้างสังคมแห่งการเรียนรู้แบบไร้ข้อจำกัด',
         link: 'https://www.appdisqus.com/open-to-read-free-e-books-more-than-1000-titles-through-the-hibrary-application/',
         imageLink:
            'https://www.appdisqus.com/wp-content/uploads/2021/10/Hibrary-03-800x534.jpg'
      },
      //? news-7
      {
         title: '33 ห้องสมุดประชาชน เปิดให้อ่านอีบุ๊กฟรี กว่า 1,000 ผ่านแอปฯ ไฮบรารี่',
         publisher: 'MThai',
         description:
            'ห้องสมุดประชาชนยุคใหม่ปรับตัวสู่ระบบดิจิตอล – ออนไลน์อย่างแท้จริง ลดอุปสรรคการอ่าน แก้ปัญหาคนไม่เข้าห้องสมุด สร้างสังคมแห่งการเรียนรู้แบบไร้ข้อจำกัด ล่าสุด 33 ห้องสมุดประชาชน เปิดให้บริการอ่านอีบุ๊ก ฟรี กว่า 1,000 หัวหนังสือ ผ่านแอปพลิเคชัน ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กรที่สมบูรณ์ที่สุดของไทย หวังช่วยคลายเครียดประชาชนช่วงน้ำท่วม – วิกฤตโควิด พร้อมช่วยเสริมเด็กเรียนออนไลน์ คาดดึงดูดผู้ร่วมใช้งานห้องสมุดเพิ่มได้กว่า 20,000 คน',
         link: 'https://mthai.com/campus/220697.html',
         imageLink:
            'https://img-ha.mthcdn.com/ltkk5WaQBAOqHQrALvoHwPv9xWw=/mthai.com/app/uploads/2021/10/Hibrary-01-1024x683.jpg'
      },
      //? news-8
      {
         title: 'ไฮเท็คซ์ฯเขย่าสูตรใหม่ ผุดห้องสมุดออนไลน์ หวังสลัดภาพอีบุ๊ก',
         publisher: 'ประชาชาติ',
         description:
            'โควิด-19 ถือเป็นตัวเร่งสำคัญทำให้อุตสาหกรรมหนังสืออิเล็กทรอนิกส์ หรืออีบุ๊ก (e-Book) โตขึ้นแบบก้าวกระโดดด้วยการเปลี่ยนพฤติกรรมการอ่านหนังสือของผู้บริโภคไปอย่างชัดเจน โดยวันนี้ผู้บริโภคไม่ยึดติดแล้วว่าต้องอ่านจากหนังสือเล่มหรืออีบุ๊กน',
         link: 'https://www.prachachat.net/ict/news-834176',
         imageLink:
            'https://www.prachachat.net/wp-content/uploads/2022/01/Face-new-ver-1-728x381.jpg'
      },
      //? news-9
      {
         title: 'ไฮบรารี่ ระบบห้องสมุดออนไลน์ ไฮบรารี่ สบช่องโตบุกตลาดลูกค้าองค์กร ตั้งเป้าปีหน้าโต 100%',
         publisher: 'MarketPlus',
         description:
            'ภาครัฐและเอกชนเร่งเดินหน้าสู่ยุคดิจิทัล ทรานส์ฟอร์เมชั่น เต็มตัว หลังโควิด-19 และการเข้าถึงเทคโนโลยีดิจิทัล เป็น 2 ตัวเร่งสำคัญ ด้าน ไฮเท็คซ์ อินเตอร์แอคทีฟ รับอานิสงค์สบช่องส่ง ไฮบรารี่ บุกตลาดหน่วยงานภาครัฐและองค์กรธุรกิจ ล่าสุดคว้างานใหญ่ระบบห้องสมุดออนไลน์ การไฟฟ้าส่วนภูมิภาค พร้อมเผยแผนปี 65 วางเป้าโต 100%',
         link: 'https://www.marketplus.in.th/content/detail.php?id=11075',
         imageLink:
            'https://www.marketplus.in.th/public/images/upload/1640152879.jpeg'
      },
      //? news-10
      {
         title: 'ไฮเท็คซ์ อินเตอร์แอคทีฟ และ สยามจุลละมณฑล ร่วมส่งมอบ ไฮบรารี่ ห้องสมุดออนไลน์ แก่ห้องสมุดประชาชนจังหวัดอุทัยธานี',
         publisher: 'TruePlookpanya',
         description:
            'นายพัฒนา พิลึกฤาเดช ผู้ก่อตั้งและผู้อำนวยการฝ่ายปฏิบัติการ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด และ นายพสิษฐ์สัทคุณ จุลละมณฑล กรรมการและผู้อำนวยการฝ่ายเทคโนโลยีสารสนเทศ บริษัท สยามจุลละมณฑล จำกัด',
         link: 'https://www.trueplookpanya.com/blog/content/90449/-newgen-new-',
         imageLink:
            'https://static.trueplookpanya.com/tppy/member/m_537500_540000/539361/cms/images/Photo%20Hibrary%20-%20%E0%B8%AB%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%AA%E0%B8%A1%E0%B8%B8%E0%B8%94%E0%B8%AD%E0%B8%B8%E0%B8%97%E0%B8%B1%E0%B8%A2.jpg'
      },
      //? news-11
      {
         title: `‘โควิด-เรียนออนไลน์-นักอ่านรุ่นใหม่’ ทำตลาดอีบุ๊กโต 100% ไฮเท็คซ์ เปิดตัว “ไฮบรารี่”`,
         publisher: 'มติชน',
         description: `ข่าวดีวงการศึกษาไทย ภาคเอกชน ไฮเท็คซ์ อินเตอร์แอคทีฟ และ สยามจุลละมณฑล พัฒนาระบบห้องสมุดออนไลน์ ช่วยสร้างการศึกษาอย่างเท่าเทียม ล่าสุดต่อยอดความสำเร็จจากระบบห้องสมุดประชาชนออนไลน์ ส่งมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ ต้นแบบ แก่ สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย (กศน.) กระทรวงศึกษาธิการ ช่วยเพิ่มโอกาสทางการศึกษาและการเข้าถึงแหล่งความรู้แบบไร้พรมแดนด้วยสื่อดิจิตอลและระบบออนไลน์ พร้อมเปิดให้ประชาชนอ่าน อีบุ๊ก แบบถูกลิกสิทธิ์ ฟรี กว่า 1,500 รายการ`,
         link: 'https://www.matichon.co.th/book/news_3004235',
         imageLink: `https://www.matichon.co.th/wp-content/uploads/2021/10/k.%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2-k.%E0%B8%AA%E0%B8%B8%E0%B8%A0%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%A9%E0%B8%93%E0%B9%8C-728x485.jpg`
      },
      //? news-12
      {
         title: `ไฮเท็คซ์ อินเตอร์แอคทีฟ ร่วม สยามจุลละมณฑล ต่อยอดความสำเร็จห้องสมุดประชาชนออนไลน์ ส่งมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ แก่ สำนักงาน กศน. กระทรวงศึกษาธิการ ช่วยสร้างการศึกษาอย่างเท่าเทียม ไร้พรมแดน พร้อมเปิดให้อ่าน อีบุ๊ก ฟรี 1,500 รายการ`,
         publisher: 'มติชน',
         description: `นายพัฒนา พิลึกฤาเดช ผู้ก่อตั้งและผู้อำนวยการฝ่ายปฏิบัติการ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด สตาร์ทอัพสัญญาติไทย หนึ่งในกลุ่ม บริษัท บีทูเอส จำกัด ในเครือ เซ็นทรัล รีเทล กล่าวถึงการเติบโตของตลาดหนังสืออิเล็กทรอนิกส์ หรือ อีบุ๊ก และสื่อดิจิตอล ว่า ในปี 2564 มีการเติบโตสูงถึง 100% มูลค่าตลาดรวมแตะ 2,000 ล้านบาท ซึ่งทางบริษัทและ เมพ คอร์ปอเรชั่นซึ่งเป็นบริษัทแม่ร่วมกันเป็นผู้นำตลาดครองส่วนแบ่งมากกว่า 70% และมีอีบุ๊กให้บริการมากที่สุดในตลาดอีกด้วย`,
         link: 'https://www.matichonweekly.com/publicize/article_516588',
         imageLink: `https://www.matichonweekly.com/wp-content/uploads/2022/02/Hibrary-Photo-02.jpg`
      },

      //? news-13
      {
         title: `โควิด-19 – เทคโนโลยีดิจิทัล 2 ตัวเร่งภาครัฐและเอกชน ปรับตัวสู่ ดิจิทัล ทรานส์ฟอร์เมชั่น”`,
         publisher: 'มติชน',
         description: `ข่าวดีวงการศึกษาไทย ภาคเอกชน ไฮเท็คซ์ อินเตอร์แอคทีฟ และ สยามจุลละมณฑล พัฒนาระบบห้องสมุดออนไลน์ ช่วยสร้างการศึกษาอย่างเท่าเทียม ล่าสุดต่อยอดความสำเร็จจากระบบห้องสมุดประชาชนออนไลน์ ส่งมอบนวัตกรรมเครือข่ายห้องสมุดออนไลน์ ไฮบรารี่ ต้นแบบ แก่ สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย (กศน.) กระทรวงศึกษาธิการ ช่วยเพิ่มโอกาสทางการศึกษาและการเข้าถึงแหล่งความรู้แบบไร้พรมแดนด้วยสื่อดิจิตอลและระบบออนไลน์ พร้อมเปิดให้ประชาชนอ่าน อีบุ๊ก แบบถูกลิกสิทธิ์ ฟรี กว่า 1,500 รายการ`,
         link: 'https://www.matichonweekly.com/eco-bus/article_501169',
         imageLink: `https://www.matichonweekly.com/wp-content/uploads/2021/12/04-Hibrary-PEA.jpg`
      },

      //? news-14
      {
         title: `สตาร์ทอัพไทยสบช่องเปิดตัว ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กร รุกตลาดการศึกษา หน่วยงานรัฐ-เอกชน คาดกวาดลูกค้า 120 ราย สิ้นปี 64`,
         publisher: 'มติชน',
         description: `โควิด-19 – เรียนออนไลน์ – นักอ่านรุ่นใหม่ ทำตลาดอีบุ๊กโต 100% ด้าน ไฮเท็คซ์ อินเตอร์แอคทีฟ สตาร์ทอัพไทยสบช่องเปิดตัว ไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กร รุกตลาดการศึกษา หน่วยงานรัฐ-เอกชน คาดกวาดลูกค้า 120 ราย สิ้นปี 64 เผยปี 65 ตลาดโตก้าวกระโดด 100% แตะ 200 ล้านบาท`,
         link: 'https://www.matichonweekly.com/publicize/article_470466',
         imageLink: `https://www.matichonweekly.com/wp-content/uploads/2021/09/thumbnail_Hibrary-Photo-03.jpg`
      },

      //? news-15
      {
         title: `ชวนอ่าน"อีบุ๊ก"ฟรี จาก 33 ห้องสมุดประชาชน`,
         publisher: 'กรุงเทพธุรกิจ',
         description: `โล่าสุด 33 ห้องสมุดประชาชน เปิดให้บริการอ่านอีบุ๊กฟรีกว่า 1,000 หัวหนังสือ ผ่านแอปพลิเคชันไฮบรารี่ ระบบห้องสมุดออนไลน์องค์กรที่สมบูรณ์ที่สุดของไทย เพื่อช่วยให้ประชาชนได้คลายเคลียดช่วงน้ำท่วม–วิกฤตโควิด  คาดว่าจะสามารถดึงดูดผู้ใช้งานห้องสมุดเพิ่มกว่า 20,000 คน`,
         link: 'https://www.bangkokbiznews.com/lifestyle/966556',
         imageLink: `https://image.bangkokbiznews.com/uploads/images/md/2021/10/8jiImEsSewlYd83svVXN.jpg?x-image-process=style/LG-webp`
      },

      //? news-16
      {
         title: `‘ทีเคพาร์ค’ เปิดตัว แอพพ์ TK Read ตอบโจทย์นักอ่านยุค 5G มุ่งสู่ห้องสมุดดิจิทัลระดับชาติ`,
         publisher: 'มติชน',
         description: `เมื่อวันที่ 29 มีนาคม ที่สถานีกลางบางซื่อ ภายในงานสัปดาห์หนังสือแห่งชาติ ครั้งที่ 50 และสัปดาห์หนังสือนานาชาติ ครั้งที่ 20 ชั้น M ที่เวทีกลาง มีการจัดกิจกรรมเปิดตัวแอพพลิเคชั่นห้องสมุดดิจิทัลโฉมใหม่ TK Read โดย สถาบันอุทยานการเรียนรู้ TK Park และเสวนาหัวข้อ “ห้องสมุดดิจิทัล ตอบโจทย์นักอ่านยุคนี้ได้จริงหรือ?`,
         link: 'https://www.matichon.co.th/book/news_3260086',
         imageLink: `https://www.matichon.co.th/wp-content/uploads/2022/03/4733-728x546.jpg`
      }
   ])

   const breakPoint = {
      1: {
         slidesPerView: 3
      },
      1440.98: {
         slidesPerView: 4
      },
      1600.98: {
         slidesPerView: 5
      }
   }

   useEffect(() => {
      let list = newsList
      list = list.sort(() => Math.random() - 0.5)
      setNewsList(list)
   }, [])

   return (
      <section className="hibrary-new-section flex flex-col align-center justify-center">
         <div className="title-section">
            <h1>
               ข่าว <b>Hibrary</b>
            </h1>
         </div>
         <div className="content-container">
            <Swiper
               spaceBetween={32}
               breakpoints={breakPoint}
               pagination={{
                  dynamicBullets: true
               }}
               modules={[Pagination]}
            >
               {newsList.map((el) => (
                  <SwiperSlide className="card-box" key={el.link}>
                     <a href={el.link} target="_blank">
                        <div className="news-cover-image">
                           <div className="clip-path-cover">
                              <img
                                 src={el.imageLink}
                                 onError={(e) => onErrorImage(e)}
                              />
                           </div>
                        </div>
                     </a>
                     <div className="description-container">
                        <a href={el.link} target="_blank">
                           <h3 className="new-title"> {el.title}</h3>
                        </a>
                        <h4 className="description">{el.description}</h4>

                        <div className="publisher">
                           สำนักข่าว : <span>{el.publisher}</span>
                        </div>
                     </div>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      </section>
   )
}

export default HibraryNewSection
