import Router from './app/route'

// bundle
import 'swiper/css/bundle'

//core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";
// import "swiper/components/pagination/pagination.min.css";

const App = () => {
   return (
      <div className="app-wrapper">
         <Router />
      </div>
   )
}

export default App
