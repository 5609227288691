import { Fade } from "react-awesome-reveal";
import "./index.scss";
import SvgAnimation from "./svg-animation-object/object-1";
import SvgAnimation2 from "./svg-animation-object/object-2";

const ThirteenSection = () => {
	return (
		<>
			{/* <ParallaxLayer offset={12} speed={0.5}> */}
			<section className='thirteen-section'>
				<div className='back-door-section flex flex-col justify-center'>
					<Fade direction='up'>
						<h1 className='main-title'>ระบบหลังบ้าน</h1>
					</Fade>
					<div className='title-section margin-bottom-0'>
						<Fade cascade direction='up'>
							<h1 className='sub-title'>หน้าสรุปข้อมูล (Dashboard)</h1>
							<h2>
								สรุปข้อมูลแบบ Exclusive เช่นจำนวนสมาชิกทั้งหมด สถิติการยืม
								การจองคิวและอันดับยอดนิยม เพื่อให้สามารถดูข้อมูลได้ง่าย
								ตอบโจทย์ในการจัดสรรทรัพยากรเข้าห้องสมุดหรือติดตามการเปลี่ยน
								แปลงข้อมูลตลอดเวลา
							</h2>
						</Fade>
					</div>
					<div className='content-container flex justify-center'>
						<img src='/image/thirteen-section/back-door.png' />
					</div>
				</div>
			</section>
			{/* </ParallaxLayer> */}

			{/* <ParallaxLayer offset={13} speed={0.5}> */}
			<section className='thirteen-section'>
				<div className='upload-content-section flex flex-col justify-center'>
					<div className='title-section'>
						<Fade direction='up' cascade>
							<h1 className='main-title margin-bottom-16'>
								ระบบอัปโหลดอีบุ๊ก, วิดีโอ, พ็อดคาสต์, หนังสือเสียง
							</h1>

							<h2>
								สามารถอัปโหลดไฟล์มาตรฐาน E-Pub, PDF ทั้งยังรองรับ Video 4k
								และไฟล์เสียง MP 3
							</h2>
						</Fade>
					</div>
					<div className='content-container flex justify-center'>
						<img src='/image/thirteen-section/upload-content.png' />
					</div>
				</div>
			</section>
			{/* </ParallaxLayer> */}

			{/* <ParallaxLayer offset={14} speed={0.5}> */}
			<section className='thirteen-section'>
				<div className='manage-member-section flex flex-col justify-center'>
					<div className='title-section'>
						<Fade direction='up' cascade>
							<h1 className='main-title margin-bottom-16'>การจัดการสมาชิก</h1>
							<h2 className='flex flex-col'>
								<span>
									จัดการสมาชิกได้โดยเลือกเพิ่มสมาชิกแบบทีละรายการและเพิ่มได้ที่ละหลาย
									ๆ คนพร้อมกัน
								</span>
								<span>
									สามารถตั้งค่าผู้ใช้งานได้หลากหลาย เช่น กำหนดวันเริ่มใช้งาน
									วันหมดอายุ บล็อก และจัดการอุปกรณ์
								</span>
							</h2>
						</Fade>
					</div>
					<div className='content-container flex justify-center'>
						<SvgAnimation2 />
					</div>
				</div>
			</section>
			{/* </ParallaxLayer> */}

			{/* <ParallaxLayer offset={15} speed={0.5}> */}
			<section className='thirteen-section'>
				<div className='upload-banner-section flex flex-col justify-center'>
					<div className='title-section margin-bottom-0'>
						<Fade direction='up' cascade>
							<h1 className='main-title margin-bottom-0'>การอัปโหลดแบนเนอร์</h1>
							<h2>สามารถอัปโหลดแบนเนอร์เพื่อประชาสัมพันธ์ภายในองค์กรได้</h2>
						</Fade>
					</div>
					<div className='content-container flex justify-center'>
						<img src='/image/thirteen-section/upload-banner.png' />
					</div>
				</div>
			</section>
			{/* </ParallaxLayer> */}

			{/* <ParallaxLayer offset={16} speed={0.5}> */}
			<section className='thirteen-section'>
				<div className='statistic-section flex flex-col justify-center'>
					<div className='title-section'>
						<Fade direction='up' cascade>
							<h1 className='main-title margin-bottom-16'>
								รายงานสถิติการใช้งาน
							</h1>
							<h2>
								<p>ดูข้อมูลสำคัญของผู้ใช้งาน เช่น ระยะเวลาที่ผู้ใช้งานอ่าน</p>
								<p>
									ข้อมูลสมาชิกทั้งย้อนหลังและรายปี สามารถ Export
									ข้อมูลเพื่อนำไปวิเคราะห์ผู้ใช้งานได้
								</p>
							</h2>
						</Fade>
					</div>
					<div className='content-container flex justify-center'>
						<SvgAnimation />
					</div>
				</div>
			</section>
			{/* </ParallaxLayer> */}
		</>
	);
};

export default ThirteenSection;
