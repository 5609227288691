import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const Redirect = () => {
   const [params] = useSearchParams()

   useEffect(() => {
      window.location.href = params.get('redirect') ?? 'https://www.hibrary.me/'
   }, [])

   return <></>
}

export default Redirect
