import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";

interface FooterProps {
  visible: boolean;
  handleModal: (visible: boolean) => void;
}

const Footer = (props: FooterProps) => {
  const twitterLink = "https://twitter.com/hibrary_hytexts";
  const facebookLink = "https://www.facebook.com/HibrarybyHytexts";
  const instagramLink = "https://www.instagram.com/hytexts/";
  const lineLink = "https://line.me/ti/p/%40hytexts";

  return (
    <div className="footer">
      <Button shape="round" onClick={() => props.handleModal(props.visible)}>
        ทดลองใช้ห้องสมุด
      </Button>
      <img
        src="/image/fourteen-section/footer-background.png"
        id="background"
      />

      <div className="top-footer">
        <div className="content-container">
          <div>
            <h4>Address</h4>
            <p>เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702</p>
            <p> ซอยลาดพร้าว 3 ถนนลาดพร้าว แขวงจอมพล เขตจตุจักร</p>
            <p> กรุงเทพมหานคร 10900</p>
          </div>

          <div className="flex flex-col justify-center align-center">
            <div>
              <img
                src="/image/fourteen-section/hibrary-logo.svg"
                className="logo-image"
              />
            </div>
            <div className="flex align-center ">
              <a href="https://play.google.com/store/apps/details?id=com.hti.elibrary.android&hl=th&gl=US">
                <img
                  src="/image/fourteen-section/app-store.png"
                  className="store-icon"
                  style={{ height: "55px" }}
                />
              </a>

              <a href="https://apps.apple.com/us/app/hibrary-%E0%B8%AB-%E0%B8%AD%E0%B8%87%E0%B8%AA%E0%B8%A1-%E0%B8%94%E0%B8%AD%E0%B8%AD%E0%B8%99%E0%B9%84%E0%B8%A5%E0%B8%99/id1519192143">
                <img
                  src="/image/fourteen-section/google-store.png"
                  className="store-icon"
                  style={{ height: "40px" }}
                />
              </a>
            </div>
          </div>

          <div style={{ textAlign: "right" }}>
            <h4>Contact Us</h4>
            <p>โทร. 02-024-6690 (จันทร์-ศุกร์ 9.00-18.00 น.)</p>
            <p>Customer Service : 089-134-7470</p>
            <p>contact@hytexts.com</p>
            <Space size={16}>
              <a href={lineLink}>
                <img src="/image/fourteen-section/line-icon.svg" />
              </a>
              <a href={instagramLink}>
                <img src="/image/fourteen-section/instagram-icon.svg" />
              </a>
              <a href={twitterLink}>
                <img src="/image/fourteen-section/twitter-icon.svg" />
              </a>
              <a href={facebookLink}>
                <img src="/image/fourteen-section/facebook-icon.svg" />
              </a>
            </Space>
          </div>
        </div>
      </div>

      <div className="bottom-footer flex">
        <div className="content-container">
          <div>
            Powered by{" "}
            <a
              href="https://www.hytexts.com/"
              target="_blank"
              style={{ textDecoration: "underline", color: "#fff" }}
            >
              Hytexts Interactive Limited
            </a>
          </div>

          <div>
            Copyright ©2021 All Right Reserved. |{" "}
            <Link
              to="/terms-and-conditions"
              target="_blank"
              style={{ color: "#fff" }}
            >
              เงื่อนไขและข้อตกลง
            </Link>{" "}
            |{" "}
            <Link
              to="/privacy-policy"
              target="_blank"
              style={{ color: "#fff" }}
            >
              นโยบายความเป็นส่วนตัว
            </Link>{" "}
          </div>

          <div>Customer Service : 089-134-7470, 02-024-6690</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
