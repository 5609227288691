import { Fade } from "react-awesome-reveal";
import "./index.scss";

const SixSection = () => {
  return (
    <section className="six-section flex justify-center align-center">
      <div className="content-container">
        <div className="title-section">
          <Fade cascade direction="up">
            <h1>
              สำหรับ <b>E-PUB</b> รองรับการ<b>อ่านออกเสียง</b> (text to speech)
            </h1>
            <h2>
              เพื่อเข้าถึงกลุ่มที่มีปัญหาในการอ่าน( Accessible ) ที่มีใน{" "}
              <b>Hibrary</b> เท่านั้น
            </h2>
          </Fade>
        </div>
        <div className="flex justify-center">
          <img src="/image/six-section/mobile-frame.png" />
        </div>
      </div>
    </section>
  );
};

export default SixSection;
