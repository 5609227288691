import { Fade } from "react-awesome-reveal";
import "./index.scss";

const FiveSection = () => {
  return (
    <section className="five-section flex align-center">
      <img src="/image/background-2.png" id="background" />
      <div className="content-container">
        <Fade cascade direction="up">
          <h1 className="title">ฟีเจอร์การอ่าน E-book </h1>
          <h1 className="sub-title">ตามมาตรฐานสากล ทั้งไฟล์ PDF และ E-PUB</h1>
        </Fade>
        <div className="cover-image">
          <img src="/image/five-section/mobile-app.png" />

          <div id="text-one">
            <Fade cascade direction="down">
              <p>ระบบการอ่าน</p>
              <b>ฟังก์ชันจัดเต็ม</b>
            </Fade>
          </div>
          <div id="text-two">
            <Fade cascade direction="up">
              <p>เปลี่ยนรูปแบบการอ่าน</p>
              <p>
                ทั้งการอ่าน<b>แนวตั้งและแนวนอน</b>
              </p>
            </Fade>
          </div>
          <div id="text-three">
            <Fade cascade direction="up">
              <p>ไฟล์ E-PUB</p>
              <p>
                <b>เปลี่ยนฟอนต์และ</b>
              </p>
              <p>
                <b>เพิ่มลดขนาดได้</b>
              </p>
            </Fade>
          </div>

          <div id="text-four">
            <Fade cascade direction="up">
              <p>
                <b>จดบันทึก</b>
              </p>
              <p>ทุกข้อความสำคัญ</p>
            </Fade>
          </div>

          <div id="text-five">
            <Fade cascade direction="down">
              <p>
                <b>เปลี่ยนธีม</b>
                ตัวอ่านได้ถึง <b>4 สี</b>
              </p>
              <p>ตอบโจทย์นักอ่าน</p>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FiveSection;
