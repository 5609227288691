import React, { useEffect, useState } from "react";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";

import FirstSection from "../../components/first-section";
import FiveSection from "../../components/five-section";
import FourSection from "../../components/four-section";
import SixSection from "../../components/six-section";
import ThirdSection from "../../components/third-section";
import SevenSection from "../../components/seven-section";
import EightSection from "../../components/eight-section";
import NineSection from "../../components/nine-section";
import ElevenSection from "../../components/eleven-section";
import TwelveSection from "../../components/twelve-section";
import ThirteenSection from "../../components/thirteen-section";
import FourteenSection from "../../components/fourteen-section";
import SecondSection from "../../components/second-section";
import Footer from "../../components/footer";
import ContactModal from "../../components/modal/contact-modal";
import HibraryNewSection from "../../components/hibrary-news-section";
import RegisterModal from "../../components/modal/register-modal";
import RegisterField from "../../components/register-field";

const LandingPage = () => {
  //   const [scroll, setScroll] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    //  const position = window.scrollY;
    //  setScroll(position);
  };

  const handleModal = (modalVisible: boolean) => {
    setVisible(!modalVisible);
  };

  return (
    <>
      <FirstSection visible={visible} handleModal={handleModal} />
      <RegisterField onOpen={() => setRegisterModal(true)} />
      <SecondSection />
      <HibraryNewSection />
      <ThirdSection />
      <FourSection />
      <FiveSection />
      <SixSection />
      <SevenSection />
      <EightSection />
      <NineSection />
      <ElevenSection />
      <TwelveSection />
      <ThirteenSection />
      <FourteenSection />
      <Footer visible={visible} handleModal={handleModal} />
      <ContactModal visible={visible} onClose={() => setVisible(false)} />
      <RegisterModal
        visible={registerModal}
        onClose={() => setRegisterModal(false)}
      />
    </>
  );
};

export default LandingPage;

{
  /* <Parallax pages={18.75} className="app-wrapper">
        <ParallaxLayer offset={0} speed={0.5}>
          <FirstSection />
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.5}>
          <SecondSection />
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={0.5}>
          <ThirdSection />
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0.5}>
          <FourSection />
        </ParallaxLayer>
        <ParallaxLayer offset={4} speed={0.5}>
          <FiveSection />
        </ParallaxLayer>
        <ParallaxLayer offset={5} speed={0.5}>
          <SixSection />
        </ParallaxLayer>
        <ParallaxLayer offset={6} speed={0.5}>
          <SevenSection />
        </ParallaxLayer>
        <ParallaxLayer offset={7} speed={0.5}>
          <EightSection />
        </ParallaxLayer>
        <ParallaxLayer offset={8} speed={0.5}>
          <NineSection />
        </ParallaxLayer>
        <ParallaxLayer offset={10} speed={0.5}>
          <ElevenSection />
        </ParallaxLayer>
        <ParallaxLayer offset={11} speed={0.5}>
          <TwelveSection />
        </ParallaxLayer>

        <ThirteenSection />

        <ParallaxLayer offset={17} speed={2.5}>
          <FourteenSection />
        </ParallaxLayer>

        <ParallaxLayer offset={18} speed={0.5}>
          <Footer />
        </ParallaxLayer>
      </Parallax> */
}
