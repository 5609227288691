import { Fade } from "react-awesome-reveal";
import "./index.scss";

interface iconMap {
  icon: string;
  description: string;
}

const SecondSection = () => {
  const iconMap: iconMap[] = [
    {
      icon: "/image/second-section/icon-",
      description:
        "<p>แอปพลิเคชันจะเปลี่ยนธีมไปตาม อัตลักษณ์องค์กร</p>  <p>หรือ ร้านค้านั้นๆ</p>",
    },
    {
      icon: "/image/second-section/icon-",
      description:
        "<p>แอปพลิเคชันจะได้รับการอัปเดตฟีเจอร์ใหม่ตลอด </p> <p>พร้อมรองรับ OS เวอร์ชั่นล่าสุดเสมอ</p>",
    },
    {
      icon: "/image/second-section/icon-",
      description:
        "<p>รองรับการใช้งานทั้งในระบบ iOS, Andriod </p> <p>และเว็บบราว์เซอร์</p>",
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>Exclusive อีบุ๊ค ในรูปแบบ E-PUB</p> 
        <p>เฉพาะบริการของ Hibrary เท่านั้น</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>ฟีเจอร์การอ่าน E-book ตามมาตรฐานสากล</p> 
      <p>ทั้งไฟล์ PDF และ E-PUB</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>รองรับการอ่านออกเสียงเพื่อเข้าถึงกลุ่มที่มีปัญหา</p>
       <p>ในการอ่าน(Accessible)</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>มาพร้อมระบบจัดการสิทธิ์ทรัพยากร </p>
      <p>การยืม - คืน - จองคิว ที่มีประสิทธิภาพ</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>เก็บสถิติการอ่านเพื่อวัดผลความคุ้มค่า</p>
      <p>ของทรัพยากร</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>ระบบการค้นหาห้องสมุดที่อยู่ในพื้นที่ให้บริการ</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>รองรับการใช้งานแบบ Walk in โดยการสแกน</p>
      <p>qr code เพื่อเข้าใช้งานได้สะดวก</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>บริการ Cloud Storage สําหรับเผยแพร่ทรัพยากร</p> <p>รองรับ อีบุ๊ก, วิดีโอ และ พ็อดคาสท์</p>`,
    },
    {
      icon: "/image/second-section/icon-",
      description: `<p>ไม่มีค่าระบบ หรือ  บํารุงรักษาระบบรายปี</p>`,
    },
  ];
  return (
    <div className="second-section flex align-center">
      {/* <img src='/image/second-section/background.png' id='background' /> */}
      <div className="content-container">
        <Fade direction="up">
          <h1>ใช้ Hibrary ดีกว่ายังไง?</h1>
        </Fade>
        <div className="grid-container">
          {iconMap.map((el, index) => (
            <Fade key={el.description} direction="up" damping={0.5 + index}>
              <div>
                <img src={el.icon + `${index + 1}` + ".svg"} />
                <div dangerouslySetInnerHTML={{ __html: el.description }}></div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
