import { Button } from 'antd'
import { useEffect } from 'react'
import { isNull } from 'lodash'
import dayjs from 'dayjs'
import './index.scss'

interface CookiePopupProps {
   open: boolean
   handleCookiePopup: (open: boolean) => void
}

const CookiePopup = (props: CookiePopupProps) => {
   useEffect(() => {
      const value = localStorage.getItem('cookie_date')

      if (!isNull(value)) {
         const currentAction = value.split('_')[0]
         const currentDate = parseInt(value.split('_')[1])
         const timeDifferenceInDay = dayjs().diff(
            dayjs.unix(currentDate),
            'day'
         )

         if (
            (currentAction === 'accept' && timeDifferenceInDay < 30) ||
            (currentAction === 'reject' && timeDifferenceInDay < 7)
         ) {
            props.handleCookiePopup(false)
         }
      }
   }, [])

   const handleClickButton = (buttonAction: string) => {
      props.handleCookiePopup(false)
      localStorage.setItem('cookie_date', `${buttonAction}_${dayjs().unix()}`)
   }

   return (
      <>
         <div
            className="overlayContainer"
            // style={
            //    props.open
            //       ? {
            //            position: 'fixed',
            //            background: 'rgba(0, 0, 0, 0.8)',
            //            width: '100%',
            //            height: '100%',
            //            zIndex: 99
            //         }
            //       : {}
            // }
         />
         <div className={props.open ? `cookieTab` : `cookieTab fadeOut`}>
            <div>
               <p className="title">
                  ความยินยอมข้อมูลส่วนบุคคลของคุณ (Cookies Policy)
               </p>
               <p>
                  <span>
                     เว็บไซต์ Hytexts ใช้คุกกี้ซึ่งทำให้เว็บไซต์ใช้งานง่ายขึ้น{' '}
                  </span>
                  <a href="/cookie-policy" className="link" target="_blank">
                     เรียนรู้เกี่ยวกับคุกกี้ของเบราว์เซอร์เพิ่มเติม
                  </a>
               </p>
            </div>
            <div>
               <Button
                  shape="round"
                  size="large"
                  className="cancelButton"
                  onClick={() => handleClickButton('reject')}
               >
                  ไม่ยอมรับ
               </Button>
               <Button
                  shape="round"
                  size="large"
                  className="acceptButton"
                  onClick={() => handleClickButton('accept')}
               >
                  ยอมรับ
               </Button>
            </div>
         </div>
      </>
   )
}

export default CookiePopup
