import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NotFound from '../components/not-found'
import LandingPage from '../pages/landing-page'
import Privacy from '../pages/privacy-policy'
import RedirectSso from '../pages/redireact-sso'
import SSOSignOut from '../pages/signOut-sso'
import Redirect from '../pages/signOut-sso/redirect'
import TermsAndCondition from '../pages/terms-and-conditions'
import CookiePopup from '../components/cookiePopup/CookiePopup'
import CookiePolicy from '../pages/cookie-policy/CookiePolicy'

const Router = () => {
   const [cookiePopup, setCookiePopup] = useState<boolean>(true)

   useEffect(() => {
      let hiddenCookiePopup = ['ssoredirect', 'ssoredirect']
      for (let i = 0; i < hiddenCookiePopup.length; i++) {
         const element = hiddenCookiePopup[i]
         if (window.location.pathname.includes(element)) {
            setCookiePopup(false)
            return
         }
      }
   }, [window.location.pathname])

   return (
      <>
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<LandingPage />} />
               <Route
                  path="/terms-and-conditions"
                  element={<TermsAndCondition />}
               />
               <Route path="/privacy-policy" element={<Privacy />} />
               <Route path="/cookie-policy" element={<CookiePolicy />} />
               <Route path="/ssoredirect/:stage" element={<RedirectSso />} />
               <Route path="/ssosignout" element={<SSOSignOut />} />
               <Route path="/redirect" element={<Redirect />} />
               <Route
                  path="*"
                  element={
                     <NotFound
                        handleCookiePopup={(open: boolean) =>
                           setCookiePopup(open)
                        }
                     />
                  }
               />
            </Routes>
         </BrowserRouter>
         <CookiePopup
            open={cookiePopup}
            handleCookiePopup={(open: boolean) => setCookiePopup(open)}
         />
      </>
   )
}

export default Router
