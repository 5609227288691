import { Bounce, Slide } from "react-awesome-reveal";
import "./index.scss";

const ThirdSection = () => {
  return (
    <section className="third-section flex align-center">
      <img src="/image/background-2.png" id="background-flip" />
      <div className="content-container">
        <div>
          <Slide direction="up" cascade>
            <h1>
              องค์กรต่าง ๆ สามารถมี <b>ห้องสมุดดิจิตอล</b> เป็นของตัวเองได้
            </h1>
            <h2>แอปพลิเคชันเปลี่ยนธีมตามอัตลักษณ์องค์กร หรือ ร้านค้านั้น</h2>
          </Slide>
        </div>

        <div className="image-cover flex justify-center">
          <div style={{ overflow: "hidden" }}>
            <Slide direction="up">
              <img
                src="/image/third-section/mobile-image.svg"
                id="mobile-image"
              />
            </Slide>
          </div>

          <img
            src="/image/third-section/gradient-image.png"
            id="gradient-image"
          />

          <div id="organization">
            <Bounce delay={1700}>
              <div className="flex align-center">
                <span>ชื่อองค์กร</span>
                <img
                  src="/image/third-section/arrow-icon.svg"
                  className="arrow"
                />
              </div>
            </Bounce>
          </div>

          <div id="themeColor">
            <Bounce delay={1100}>
              <div className="flex align-center">
                <img
                  src="/image/third-section/arrow-icon.svg"
                  className="arrow"
                />
                <span>สีภายในแอป</span>
              </div>
            </Bounce>
          </div>

          <div id="logo">
            <Bounce delay={1400}>
              <div className="flex align-center">
                <img
                  src="/image/third-section/arrow-icon.svg"
                  className="arrow"
                />
                <span>Logo องค์กร</span>
              </div>
            </Bounce>
          </div>

          <div id="banner">
            <Bounce delay={2000}>
              <div className="flex align-center">
                <span>แบนเนอร์</span>
                <img
                  src="/image/third-section/arrow-icon.svg"
                  className="arrow"
                />
              </div>
            </Bounce>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
