import React, { useState } from "react";
import Footer from "../../components/footer";
import ContactModal from "../../components/modal/contact-modal";

import "./index.scss";

const TermsAndCondition = () => {
	const [visible, setVisible] = useState<boolean>(false);

	const handleModal = (modalVisible: boolean) => {
		setVisible(!modalVisible);
	};
	return (
		<>
			<div className='terms-condition-container'>
				<h1 className='header'>เงื่อนไขและข้อตกลงในการใช้บริการ Hibrary</h1>
				<div className='detail-container'>
					<p>
						โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน
						หลังจากลงทะเบียนเสร็จสมบูรณ์แล้ว
						ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างผู้ใช้งานกับ บริษัท
						ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด หรือ Hibrary
					</p>
					<p>โดยทั้งสองฝ่ายตกลงทำข้อตกลงกัน ซึ่งมีข้อความดังต่อไปนี้</p>
					<p>หนังสืออิเล็กทรอนิกส์ (E-BOOK)</p>

					<ul>
						<li>
							1. หนังสืออิเล็กทรอนิกส์ที่เผยแพร่ใน Hibrary
							จะถูกเข้ารหัสเพื่อจัดการลิขสิทธ์ (Digital Right Management)
						</li>
						<li>
							2. สมาชิกจะต้องสมัคร Adobe ID
							ก่อนที่จะดาวน์โหลดหนังสืออิเล็กทรอนิกส์
							เพื่อที่จะสามารถนำไปอ่านในอุปกรณ์พกพาอื่นๆได้ เช่น iPad iPhone
							Android e-Reader Pc และ Mac
						</li>
						<li>
							3. สมาชิกสามารถ Authorize อุปกรณ์พกพาได้สูงสุด 5 อุปกรณ์ ต่อ 1
							Adobe ID
						</li>

						<li>
							4. หนังสืออิเล็กทรอนิกส์ และ ตัวอย่างหนังสืออิเล็กทรอนิกส์
							ที่ถูกจัดจำหน่ายโดย Hibrary ห้ามทำซ้ำ ดัดแปลง แก้ไข เผยแพร่
							จัดจำหน่าย โดยไม่ได้รับอนุญาตจาก Hibrary
						</li>
					</ul>

					<p>บัญชีผู้ใช้งาน</p>

					<ul>
						<li>
							1. ผู้ที่ต้องการสมัครสมาชิก
							ต้องกรอกข้อมูลของตนเองที่เป็นจริงให้แก่ Hibrary ให้ครบทุกข้อ
						</li>
						<li>
							2. ผู้ใดแอบอ้าง หรือกระทำการใดๆ อันเป็นการละเมิดสิทธิ์ส่วนบุคคล
							โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครสมาชิกเพื่อให้ได้สิทธิ์การสมาชิก
							ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกำหนดไว้
						</li>
						<li>
							3. เพื่อความเป็นส่วนตัวและความปลอดภัยในข้อมูลของท่านเอง Hibrary
							ขอแจ้งให้ทราบว่า เป็นหน้าที่ของสมาชิกในการรักษาข้อมูลชื่อ Login
							และ Password โดยไม่บอกให้ผู้อื่นทราบ ID
						</li>
						<li>
							4. สมาชิก สามารถเปลี่ยนแปลงรหัสผ่าน (Password)
							ด้วยตนเองได้ตลอดระยะเวลาการใช้งาน โดยไม่ต้องแจ้งให้ Hibrary ทราบ
							ทั้งนี้สมาชิก ตกลงให้รหัสผ่าน (Password)
							ที่เปลี่ยนแปลงไปในแต่ละขณะดังกล่าว เป็นรหัสผ่านการใช้บริการของ
							Hibrary ที่มีผลผูกพันสมาชิก
							ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ทุกประการ
						</li>
						<li>
							5. การกระทำการใดๆ โดยผ่านบริการ ไม่ว่ากรณีใดๆก็ตาม
							หากกระทำโดยใช้รหัสสมาชิกและรหัสผ่านของสมาชิกและ/หรือตรงตามวิธีการหรือเงื่อนไขที่บริษัทกำหนดไว้เกี่ยวกับการใช้บริการ
							Hibrary แล้ว สมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์
							โดยสมาชิกไม่ต้องลงลายมือชื่อเป็นหลักฐานในเอกสารใดๆ อีก
							และยินยอมรับผิดชอบในการกระทำดังกล่าวเสมือนหนึ่งว่าสมาชิกเป็นผู้กระทำด้วยตนเอง
							ทั้งนี้ไม่ว่าจะเกิดขึ้นด้วยความทุจริตของบุคคลภายนอกหรือไม่ก็ตาม
							สมาชิกจะไม่เรียกร้องให้บริษัทต้องรับผิดชอบแต่อย่างใด
						</li>
						<li>
							6. รหัสผ่าน (Password)
							ถือเป็นความลับเฉพาะตัวของสมาชิกที่ต้องเก็บรักษาและปกปิดมิให้ผู้อื่นล่วงรู้
							สมาชิกต้องเก็บรักษาไว้ด้วยตนเอง
							หากมีการใช้บริการรายการใดๆโดยใช้รหัสผ่าน (Password) ของสมาชิกแล้ว
							ให้ถือว่าถูกต้องสมบูรณ์มีผลผูกพันสมาชิกเสมือนสมาชิกใช้บริการในครั้งนั้นๆ
							ด้วยตนเอง ทั้งนี้ บริษัทไม่สามารถล่วงรู้รหัสผ่าน (Password)
							ของสมาชิกได้ ดังนั้น
							บริษัทจึงไม่ต้องรับผิดชอบในความเสียหายใดๆที่เกิดขึ้นในการใช้บริการ
							Hibrary โดยรหัสผ่าน (Password) ของสมาชิก
						</li>
						<li>
							7.
							สมาชิกจำเป็นต้องตรวจสอบความถูกต้องในการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์ว่าถูกต้องหรือไม่
							ก่อนที่จะทำการตกลงชำระเงิน
							ซึ่งถ้าหากเกิดข้อผิดพลาดจากการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์อันเนื่องมาจากความไม่ถูกต้องของรายการเนื่องมาจากความผิดพลาดของสมาชิกเอง
							ทาง Hibrary จะไม่รับผิดชอบทั้งสิ้น
						</li>
						<li>
							8. ถ้า Hibrary
							พบว่าสมาชิกนำหนังสืออิเล็กทรอนิกส์และตัวอย่างหนังสืออิเล็กทรอนิกส์ของ
							Hibrary ไปทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ หรือจัดจำหน่าย
							โดยไม่ได้รับอนุญาตจาก Hibrary
							ทีมงานมีสิทธิ์ยกเลิกความเป็นสมาชิกที่ใช้บริการ Hibrary ได้โดยทันที
						</li>
						<li>
							9. หนังสืออิเล็กทรอนิกส์ที่สมาชิกสั่งใช้บริการ
							ห้ามทำซ้ำหรือดัดแปลง ห้ามให้เช่าต้นฉบับหรือสำเนางาน
							ห้ามเผยแพร่งานต่อสาธารณชน หากผู้ใดกระทำการดังกล่าว
							ถือเป็นการละเมิดลิขสิทธิ์
							ต้องรับโทษอาญาและจ่ายค่าเสียหายแก่เจ้าของลิขสิทธิ์และ Hibrary ด้วย
							หากผู้ใดรู้อยู่แล้วหรือมีเหตุอันควรรู้ว่างานใดได้ทำขึ้นโดยละเมิดลิขสิทธิ์ของผู้อื่น
							กระทำการต่อไปนี้แก่งานนั้นเพื่อหากำไร
							ให้ถือว่าผู้นั้นกระทำละเมิดลิขสิทธิ์ด้วย ได้แก่
						</li>
						<li>
							9.1 ขาย มีไว้เพื่อขาย เสนอขาย ให้เช่า เสนอให้เช่า ให้เช่าซื้อ
							หรือเสนอให้เช่าซื้อ
						</li>
						<li>9.2 เผยแพร่ต่อสาธารณชน</li>
						<li>
							9.3 แจกจ่ายในลักษณะที่อาจก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์
						</li>
						<li>
							10. สมาชิกจะต้องไม่โพสต์ข้อความที่มีเนื้อหาดังต่อไปนี้ที่ Hibrary
						</li>
						<li>
							10.1 ไม่โพสต์ข้อความซึ่งพาดพิงหรือลบหลู่ สถาบันพระมหากษัตริย์
						</li>
						<li>
							10.2 ไม่โพสต์ข้อมูลหยาบคาบ หมิ่นประมาณ
							หรือสร้างความเสียหายต่อบุคคลหนึ่งบุคคลใด
						</li>
						<li>
							11. Hibrary จะไม่รับผิดชอบต่อความสูญเสียในด้านข้อมูลสมาชิก
							ข้อมูลการสั่งซื้อ และข้อมูลทุกๆอย่างภายในเว็บไซต์หากมีการสูญหาย
							จากการถูกจารกรรมความผิดพลาดจากระบบของเครื่องแม่ข่ายที่เก็บข้อมูลหรือความผิดพลาดที่เกิดจากระบบเว็บไซต์ทั้งสิ้น
						</li>
						<li>
							12. หากพบว่าสมาชิกกระทำการที่ผิดเงื่อนไขและข้อตกลงกับ Hibrary
							ทีมงานมีสิทธิ์ที่จะยกเลิกความเป็นสมาชิกได้โดยทันที
						</li>
					</ul>

					<p>ข้อกำหนดเนื้อหาของการใช้งานสื่อองค์กร</p>
					<p>
						Hibrary ไม่มีหน้าที่ตรวจสอบ เนื้อหา เนื้อเรื่อง ภาพประกอบ ลิขสิทธิ์
						หรือส่วนหนึ่งส่วนใด ของหนังสืออิเล็กทรอนิกส์ ไฟล์เสียง
						หรือไฟล์วีดีโอ ที่ผู้ใช้บริการนำส่งข้อมูล
						ดังนั้นผู้ใช้บริการตกลงว่าจะไม่นำงานวรรณกรรมที่มีเนื้อหา เนื้อเรื่อง
						ภาพประกอบหรือส่วนหนึ่งส่วนใดดังต่อไปนี้ให้ Hibrary จัดจำหน่าย
						โฆษณาและเผยแพร่ ใน Hibrary
					</p>

					<ul>
						<li>
							1. งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาท หรือแสดงความอาฆาตมาดร้าย
							พาดพิงสถาบันพระมหากษัตริย์ รัชทายาทราชวงศ์
							หรือผู้สำเร็จราชการแทนพระองค์ เป็นอันขาด
						</li>
						<li>
							2. งานวรรณกรรมที่กล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา
							หรือคำสอนของศาสนาใดๆ ทุกศาสนา
						</li>
						<li>
							3.
							งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคลที่มีต่อบุคคลอื่นหรือหน่วยงานใด
						</li>
						<li>
							4. งานวรรณกรรมที่คัดลอกผลงาน หรือบทความบนเว็บไซต์ของบุคคลอื่น
						</li>
						<li>
							5. งานวรรณกรรมที่มีเนื้อหาละเมิดกฎหมาย
							หรือสนับสนุนกิจกรรมที่ผิดกฎหมาย
						</li>
						<li>
							6.
							งานวรรณกรรมที่มีเนื้อหามีภาพประกอบหรือภาพบุคคลอื่นที่มีลักษณะหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคล
						</li>
						<li>
							7. งานวรรณกรรมที่มีเนื้อหามีการโฆษณาในวัตถุประสงค์
							เพื่อใช้ในการโฆษณางานวรรณกรรมเดียวกันที่จัดจำหน่ายในที่อื่นนอกเหนือจาก
							Hibrary
						</li>
						<li>
							8. งานวรรณกรรมที่มี ตัวอักษร หน้าปก หรือ
							ภาพประกอบที่ละเมิดลิขสิทธิ์ของบุคคลอื่น
						</li>
					</ul>
				</div>
			</div>
			<Footer visible={visible} handleModal={handleModal} />
			<ContactModal visible={visible} onClose={() => setVisible(false)} />
		</>
	);
};

export default TermsAndCondition;
