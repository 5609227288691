/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Divider, message, Modal } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Device, ErrorResponse, removeDeviceList, Stage } from '../../axios'
import { useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import './index.scss'
import { AxiosError } from 'axios'

interface ManageDeviceProps {
   open: boolean
   onClose: () => void
   devices: Device[]
   memberUID: string
}

const ManageDevice = (props: ManageDeviceProps) => {
   const { open, devices, memberUID } = props
   const [deleteDeviceUiD, setDeleteDeviceUiD] = useState<string>()
   const [deviceList, setDeviceList] = useState<Device[] | undefined>(devices)
   const { stage } = useParams()

   useEffect(() => {
      setDeviceList(devices)
   }, [devices])

   const onRemoveDevice = (index: number, deviceName: string) => {
      Modal.confirm({
         title: 'ยืนยันการลบ',
         content: `ต้องการลบอุปกรณ์ ${deviceName} หรือไม่`,
         okText: `ยืนยัน`,
         cancelText: `ยกเลิก`,
         onOk: () => removeDevice(index)
      })
   }

   const removeDevice = async (listIndex: number) => {
      const getDevice = devices![listIndex]
      setDeleteDeviceUiD(getDevice?.deviceuid)
      try {
         const res = await removeDeviceList(
            getDevice!.devicetoken,
            stage as Stage,
            memberUID
         )

         if (res.status) {
            const newDeviceList = deviceList!.filter(
               (el, index) => listIndex !== index
            )

            setDeviceList(newDeviceList)
            setDeleteDeviceUiD(undefined)
         }
      } catch (error) {
         setDeleteDeviceUiD(undefined)
         const e = error as AxiosError<ErrorResponse>
         message.error(e!.response!.data.message)
      }
   }

   return (
      <Modal
         destroyOnClose
         footer={null}
         visible={open}
         onCancel={props.onClose}
         title={
            <div>
               <h2>จัดการอุปกรณ์</h2>
               <p style={{ margin: 0 }}>ลบอุปกรณ์เพื่อลงทะเบียนอีกครั้ง</p>
            </div>
         }
         maskClosable={false}
         wrapClassName="manage-device-modal"
      >
         <div>
            <div className="device-list">
               {deviceList?.map((device, index) => (
                  <>
                     <div className="device-item">
                        <div className="device-information">
                           <h3>{`${device.devicemodel}  ${device.osversion}`}</h3>
                           <p>
                              {device.isCurrentDevice
                                 ? 'เครื่องนี้'
                                 : `ใช้งานล่าสุดเมื่อ : ${dayjs
                                      .unix(device.regisDate)
                                      .format('DD/MM/YYYY HH:mm')}`}
                           </p>
                        </div>

                        {device.isCurrentDevice ? null : (
                           <>
                              {device.deviceuid !== deleteDeviceUiD ? (
                                 <img
                                    src={'/icons/trash.svg'}
                                    width={24}
                                    height={24}
                                    onClick={() =>
                                       onRemoveDevice(
                                          index,
                                          `${device.devicename}  ${device.osversion}`
                                       )
                                    }
                                 />
                              ) : (
                                 <LoadingOutlined style={{ fontSize: 24 }} />
                              )}
                           </>
                        )}
                     </div>
                     <Divider className="divider" />
                  </>
               ))}
            </div>
            <div className="btn-group">
               <Button
                  shape="round"
                  size="large"
                  className="re-login-btn"
                  onClick={() => window.location.reload()}
                  disabled={props.devices.length === (deviceList?.length ?? 0)}
               >
                  ลงทะเบียนอีกครั้ง
               </Button>
            </div>
         </div>
      </Modal>
   )
}

export default ManageDevice
