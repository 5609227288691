import Lottie from 'lottie-react'
import NotFoundLottie from './not_found.json'
import './index.scss'
import { useEffect } from 'react'

interface NotFoundProps {
   handleCookiePopup: (open: boolean) => void
}

const NotFound = (props: NotFoundProps) => {
   useEffect(() => {
      props.handleCookiePopup(false)
   }, [])

   return (
      <div className="not-found">
         <Lottie animationData={NotFoundLottie} />
      </div>
   )
}

export default NotFound
