import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import "./index.scss";
import "./timer/timer.scss";
import Timer from "./timer/timer1";
import Timer2 from "./timer/timer2";
import Timer3 from "./timer/timer3";
import Timer4 from "./timer/timer4";
import Timer5 from "./timer/timer5";
import Timer6 from "./timer/timer6";

const EightSection = () => {
  const [start, setStart] = useState(false);

  return (
    <section className="eight-section flex flex-col justify-center">
      <div className="content-container flex flex-col">
        <div className="title-section">
          <Fade
            cascade
            direction="up"
            onVisibilityChange={(inView) => setStart(inView)}
          >
            <h1>
              <b>เก็บสถิติการอ่าน</b>
            </h1>
            <h1>เพื่อวัดผลความคุ้มค่าของทรัพยากรที่ให้บริการ</h1>
          </Fade>
        </div>

        <div className="flex justify-center">
          <div className="left-col">
            <div className="timer-contain">
              <Timer
                uid="timer_1"
                numeric={45}
                strokeColor="#346FE8"
                trigger={start}
              />

              <Timer2
                uid="timer_2"
                numeric={55}
                strokeColor="#32B1E9"
                trigger={start}
              />

              <Timer3
                uid="timer_3"
                numeric={40}
                strokeColor="#346FE8"
                trigger={start}
              />

              <Timer4
                uid="timer_4"
                numeric={60}
                strokeColor="#DD6B51"
                trigger={start}
              />

              <Timer5
                uid="timer_5"
                numeric={35}
                strokeColor="#346FE8"
                trigger={start}
              />

              <Timer6
                uid="timer_6"
                numeric={50}
                strokeColor="#32B1E9"
                trigger={start}
              />
            </div>
          </div>

          <div className="right-col flex justify-center">
            <img src="/image/eight-section/reader-mobile.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EightSection;
