import React from "react";
import { Zoom } from "react-awesome-reveal";
import "./index.scss";

const SvgAnimation2 = () => {
  return (
    <div className="svg-container svg-object-2">
      <div className="layer-one">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_1.svg" />
        </Zoom>
      </div>

      <div className="layer-two">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_2.svg" />
        </Zoom>
      </div>

      <div className="layer-three">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_2.svg" />
        </Zoom>
      </div>

      <div className="layer-four">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_3.svg" />
        </Zoom>
      </div>

      <div className="layer-five">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_4.svg" />
        </Zoom>
      </div>

      <div className="layer-six">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_5.svg" />
        </Zoom>
      </div>

      <div className="layer-seven">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_6.svg" />
        </Zoom>
      </div>

      <div className="layer-eight">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_7.svg" />
        </Zoom>
      </div>

      <div className="layer-ten">
        <Zoom>
          <img src="/image/thirteen-section/svg-object-2/layer_8.svg" />
        </Zoom>
      </div>
    </div>
  );
};

export default SvgAnimation2;
