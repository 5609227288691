import { Button } from "antd";
import { Fade } from "react-awesome-reveal";

import "./index.scss";
import ReactPlayer from "react-player";

interface FirstSectionProps {
  visible: boolean;
  handleModal: (visible: boolean) => void;
}

const FirstSection = (props: FirstSectionProps) => {
  return (
    <section>
      <div className="first-section flex align-center">
        <img
          src="/image/first-section/top-bg.png"
          id="top-background"
          alt="top-bg"
        />
        <img
          src="/image/first-section/right-bg.png"
          id="right-background"
          alt="right-bg"
        />

        <div className="flex align-center content-container">
          <div className="left-col">
            <Fade cascade direction="left" damping={0.3}>
              <img src="/image/first-section/landing-logo.png" />
              <h1>ระบบห้องสมุดหนังสืออิเล็กทรอนิกส์(E-library)สําหรับองค์กร</h1>
              <p>
                Hibrary ระบบห้องสมุดออนไลน์ (E-library)
                สำหรับองค์กรที่มียอดเติบโตและความพึงพอใจของผู้ใช้งานสูงที่สุด
              </p>
              <p>
                ให้บริการในกว่า 100 องค์กร ครอบคลุมทั้ง โรงเรียน, มหาวิทยาลัย,
                หน่วยงานรัฐ, บริษัทเอกชน, โรงพยาบาล และห้องสมุดประชาชน
              </p>
              <p>
                อีกทั้งเป็นผู้พัฒนา แอปพลิเคชันห้องสมุดดิจิทัล <b><a href="https://read.tkpark.or.th" target="_blank" rel="noreferrer">TK READ</a></b> ให้กับ  <b>สถาบันอุทยานการเรียนรู้</b>
                สังกัดสำนักงานบริหาร และพัฒนาองค์ความรู้ (องค์การมหาชน)
              </p>
              <Button
                shape="round"
                type="primary"
                size="large"
                className="primary-background-button"
                onClick={() => props.handleModal(props.visible)}
              >
                ทดลองใช้ห้องสมุด
              </Button>
            </Fade>
          </div>

          <div className="right-col">
            
            <Fade>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/embed/NHi-KyceU-U"
                playing={true}
                controls={true}
                width="100%"
                height="100%"
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
