import { useEffect } from "react";
import CountUp from "react-countup";

interface TimerProps {
  uid: string;
  numeric: number;
  strokeColor: string;
  trigger: boolean;
}

const Timer3 = (props: TimerProps) => {
  useEffect(() => {
    const timer3 = document.getElementById("circle-3");
    if (props.trigger) {
      timer3!.classList.add("anim-3");
    } else {
      timer3!.classList.remove("anim-3");
    }
  }, [props.trigger]);
  return (
    <div className="timer-3">
      <div className="timer-container">
        <div className="timer-counter">
          {props.trigger ? (
            <CountUp end={props.numeric} duration={2} className="number" />
          ) : (
            <span className="number">{props.numeric}</span>
          )}
        </div>

        <svg
          width="291"
          height="291"
          viewBox="0 0 291 291"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M290.751 145.209C290.751 173.928 282.225 202.003 266.251 225.883C250.277 249.762 227.572 268.374 201.008 279.364C174.445 290.355 145.214 293.23 117.014 287.628C88.8141 282.025 62.9107 268.195 42.5796 247.887C22.2484 227.579 8.40277 201.705 2.79342 173.538C-2.81593 145.37 0.0629785 116.173 11.0661 89.6398C22.0692 63.1064 40.7024 40.4278 64.6093 24.4721C88.5162 8.51633 116.623 0 145.376 0C183.932 0 220.908 15.2987 248.172 42.5307C275.435 69.7626 290.751 106.697 290.751 145.209Z"
            fill="#001D7B"
          />
          <path
            d="M266.75 145.209C266.751 169.187 259.634 192.628 246.298 212.566C232.963 232.504 214.007 248.045 191.829 257.222C169.651 266.399 145.246 268.801 121.701 264.124C98.1559 259.447 76.5282 247.901 59.5528 230.947C42.5774 213.992 31.0168 192.39 26.3328 168.872C21.6489 145.354 24.052 120.978 33.2384 98.8241C42.4247 76.6707 57.9816 57.7358 77.9418 44.4139C97.902 31.092 121.369 23.9814 145.375 23.9814C177.564 23.9814 208.435 36.7533 231.197 59.4877C253.959 82.222 266.748 113.057 266.75 145.209Z"
            fill="#FAFAFA"
          />

          <circle
            className="percent-3 rotate"
            id="circle-3"
            cx="145"
            cy="145"
            r="95"
            fill="none"
            stroke={props.strokeColor}
            stroke-width="48"
            pathLength="100"
          />

          <path
            opacity="0.76"
            d="M199.673 53.7112L205.918 42.9233L203.796 41.7002L197.559 52.4881C198.285 52.8877 198.979 53.2954 199.673 53.7112Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M91.3433 236.291L84.8125 247.502L86.935 248.726L93.4168 237.505C92.7311 237.114 92.0209 236.698 91.3433 236.291Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M52.7874 197.2L41.7422 203.568L42.9667 205.68L54.0119 199.312C53.5956 198.61 53.1874 197.909 52.7874 197.2Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M238.009 93.2018L249.005 86.8579L247.789 84.7378L236.801 91.0817C237.209 91.783 237.617 92.4924 238.009 93.2018Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M38.9163 144.826C38.9163 144.548 38.9163 144.271 38.9163 144.01H26.418V146.456H38.9408C38.9245 145.894 38.9163 145.364 38.9163 144.826Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M251.453 143.994C251.453 144.271 251.453 144.548 251.453 144.809C251.453 145.347 251.453 145.877 251.453 146.44H264.327V143.994H251.453Z"
            fill="#001D7B"
          />
          <path
            d="M53.6772 90.9186L42.9667 84.7378L41.7422 86.8579L52.4609 93.0795C52.8609 92.3293 53.269 91.628 53.6772 90.9186Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M236.465 199.156L247.788 205.68L249.004 203.568L237.689 197.044C237.289 197.754 236.881 198.455 236.465 199.156Z"
            fill="#001D7B"
          />
          <path
            d="M93.1056 52.3089L86.9585 41.7085L84.8359 42.9316L90.983 53.532C91.6851 53.1243 92.3953 52.7329 93.1056 52.3089Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M197.227 237.351L203.757 248.766L205.88 247.543L199.3 236.127C198.639 236.543 197.937 236.951 197.227 237.351Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M145.225 38.675H146.629V26.3867H144.18V38.6832L145.225 38.675Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M145.22 250.976H144.184V264.023H146.633V250.976H145.22Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M133.303 256.823L132.617 263.346L133.27 263.411L133.956 256.937L133.303 256.823Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M157.467 33.5948L158.152 27.1123L157.467 27.0308L156.789 33.5051L157.467 33.5948Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M169.067 35.4865L170.422 29.0937L169.777 28.9551L168.422 35.3479L169.067 35.4865Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M121.695 254.939L120.332 261.324L120.977 261.462L122.34 255.069L121.695 254.939Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M180.418 38.5609L182.442 32.3474L181.814 32.1436L179.789 38.3652L180.418 38.5609Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M110.337 251.857L108.312 258.079L108.941 258.282L110.933 252.085L110.337 251.857Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M191.377 42.8091L194.047 36.8321L193.434 36.563L190.781 42.5237L191.377 42.8091Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M99.3765 247.608L96.707 253.585L97.3111 253.854L99.9724 247.877L99.3765 247.608Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M211.699 54.5838L215.544 49.3081L215.005 48.9248L211.168 54.2005L211.699 54.5838Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M79.0989 235.834L75.2539 241.118L75.7927 241.501L79.6295 236.217L79.0989 235.834Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M220.834 61.9875L225.226 57.2011L224.736 56.7607L220.328 61.5554L220.834 61.9875Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M69.9218 228.438L65.5625 233.273L66.0523 233.714L70.4116 228.87C70.2565 228.715 70.1177 228.577 69.9218 228.438Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M229.128 70.3294L233.969 65.9751L233.528 65.4858L228.695 69.8401L229.128 70.3294Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M61.63 220.088L56.7891 224.451L57.2299 224.94L62.0708 220.578C61.9566 220.423 61.7851 220.284 61.63 220.088Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M236.493 79.4946L241.775 75.6622L241.391 75.124L236.109 78.9564L236.493 79.4946Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M54.2665 210.931L48.9766 214.764L49.3684 215.302L54.6502 211.461C54.5277 211.314 54.3971 211.102 54.2665 210.931Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M248.164 99.8555L254.148 97.2054L253.878 96.6021L247.895 99.2603C247.984 99.4641 248.082 99.6598 248.164 99.8555Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M42.5915 190.562L36.6484 193.228L36.9178 193.824L42.9017 191.165C42.7792 190.929 42.6813 190.766 42.5915 190.562Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M252.352 110.839L258.572 108.817L258.368 108.197L252.164 110.203C252.213 110.423 252.278 110.635 252.352 110.839Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M38.4084 179.587L32.1797 181.601L32.3838 182.229L38.6125 180.207C38.539 180.003 38.4737 179.791 38.4084 179.587Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M255.365 122.198L261.765 120.836L261.626 120.192L255.234 121.554C255.283 121.766 255.324 121.978 255.365 122.198Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M35.3924 168.228L28.9922 169.59L29.131 170.234L35.5311 168.872C35.4822 168.66 35.4332 168.44 35.3924 168.228Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M257.199 133.801L263.672 133.124L263.607 132.472L257.117 133.149C257.142 133.369 257.166 133.589 257.199 133.801Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M33.5699 156.616L27.0391 157.301L27.1044 157.954L33.5943 157.277C33.6188 157.057 33.5862 156.836 33.5699 156.616Z"
            fill="#001D7B"
          />
          <path
            d="M33.6334 133.157L27.1434 132.472L27.0781 133.124L33.6089 133.809C33.5844 133.589 33.6171 133.369 33.6334 133.157Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M257.199 156.616C257.199 156.836 257.142 157.057 257.117 157.277L263.607 157.954L263.672 157.301L257.199 156.616Z"
            fill="#001D7B"
          />
          <path
            d="M35.5311 121.554L29.131 120.192L28.9922 120.836L35.3924 122.198C35.4332 121.986 35.4822 121.766 35.5311 121.554Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M255.365 168.228C255.365 168.44 255.283 168.66 255.234 168.872L261.626 170.234L261.765 169.59L255.365 168.228Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M252.344 179.587L252.148 180.207L258.369 182.229L258.573 181.601L252.344 179.587Z"
            fill="#001D7B"
          />
          <path
            d="M38.6125 110.203L32.3838 108.181L32.1797 108.801L38.4084 110.823C38.4737 110.636 38.539 110.424 38.6125 110.203Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M247.895 191.166L253.878 193.824L254.148 193.22L248.164 190.57C248.082 190.766 247.984 190.929 247.895 191.166Z"
            fill="#001D7B"
          />
          <path
            d="M42.8626 99.2603L36.8788 96.6021L36.6094 97.2054L42.5932 99.8637L42.8626 99.2603Z"
            fill="#001D7B"
          />
          <path
            d="M54.6502 78.9648L49.3684 75.1323L48.9766 75.6624L54.2665 79.4948C54.3971 79.3235 54.5196 79.136 54.6502 78.9648Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M236.109 211.461L241.391 215.302L241.775 214.764L236.493 210.931C236.362 211.102 236.24 211.314 236.109 211.461Z"
            fill="#001D7B"
          />
          <path
            d="M62.0708 69.8401L57.2299 65.4858L56.7891 65.9832L61.63 70.3294C61.777 70.1745 61.9566 70.0032 62.0708 69.8401Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M228.695 220.586L233.528 224.94L233.969 224.451L229.128 220.088L228.695 220.586Z"
            fill="#001D7B"
          />
          <path
            d="M70.4123 61.5638L66.0367 56.7202L65.5469 57.1605L69.9062 61.9959L70.4123 61.5638Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M220.328 228.862L224.696 233.697L225.185 233.265L220.834 228.438L220.328 228.862Z"
            fill="#001D7B"
          />
          <path
            d="M79.5866 54.2005L75.7497 48.9248L75.2109 49.3081L79.0478 54.5838L79.5866 54.2005Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M211.168 236.217L215.005 241.501L215.544 241.118L211.699 235.834L211.168 236.217Z"
            fill="#001D7B"
          />
          <path
            d="M99.9724 42.524L97.3111 36.5552L96.707 36.8161L99.3765 42.7931L99.9724 42.524Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M190.773 247.877L193.435 253.854L194.047 253.585L191.378 247.608C191.173 247.698 190.978 247.796 190.773 247.877Z"
            fill="#001D7B"
          />
          <path
            d="M110.934 38.3652L108.91 32.1436L108.281 32.3474L110.306 38.5609L110.934 38.3652Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M179.789 252.085L181.814 258.307L182.442 258.103L180.418 251.881L179.789 252.085Z"
            fill="#001D7B"
          />
          <path
            d="M122.367 35.3481L121.012 28.9634L120.367 29.0938L121.722 35.4867L122.367 35.3481Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M168.422 255.069L169.777 261.462L170.422 261.324L169.067 254.939L168.422 255.069Z"
            fill="#001D7B"
          />
          <path
            d="M133.956 33.5545L133.27 27.0801L132.617 27.1453L133.303 33.6278L133.956 33.5545Z"
            fill="#001D7B"
          />
          <path
            opacity="0.76"
            d="M156.797 256.896L157.474 263.37L158.136 263.305L157.45 256.782L156.797 256.896Z"
            fill="#001D7B"
          />
          <path
            d="M222.53 145.266C222.531 160.5 218.01 175.393 209.537 188.061C201.065 200.729 189.021 210.602 174.931 216.433C160.84 222.264 145.334 223.79 130.375 220.818C115.416 217.847 101.675 210.511 90.8904 199.738C80.1056 188.966 72.7612 175.241 69.786 160.299C66.8109 145.357 68.3386 129.869 74.1761 115.794C80.0136 101.72 89.8985 89.6904 102.581 81.2275C115.263 72.7646 130.173 68.2484 145.425 68.25C165.875 68.25 185.486 76.3642 199.946 90.8074C214.406 105.251 222.53 124.84 222.53 145.266Z"
            fill="#001D7B"
          />

          <path
            d="M133.091 193.278V181.144C133.091 179.946 133.091 178.935 133.025 178.119C133.005 177.431 132.873 176.751 132.634 176.105C132.444 175.615 132.085 175.21 131.621 174.963C131.046 174.723 130.429 174.599 129.805 174.599C129.181 174.599 128.564 174.723 127.989 174.963C127.345 175.264 126.731 175.621 126.152 176.032V193.269H118.527V181.144C118.527 179.962 118.527 178.959 118.445 178.127C118.427 177.436 118.292 176.752 118.045 176.105C117.859 175.614 117.499 175.208 117.033 174.963C116.442 174.719 115.808 174.6 115.168 174.612C114.529 174.625 113.899 174.77 113.319 175.037C112.718 175.334 112.14 175.675 111.588 176.056V193.294H103.996V168.962H111.588V171.645C112.669 170.708 113.845 169.888 115.098 169.198C116.239 168.594 117.514 168.288 118.805 168.31C120.188 168.281 121.551 168.648 122.731 169.37C123.896 170.13 124.794 171.232 125.303 172.525C126.554 171.329 127.941 170.281 129.434 169.402C130.628 168.702 131.984 168.326 133.368 168.31C134.403 168.293 135.432 168.475 136.397 168.848C137.28 169.198 138.064 169.758 138.683 170.479C139.372 171.294 139.891 172.24 140.209 173.259C140.583 174.632 140.754 176.053 140.715 177.475V193.302L133.091 193.278Z"
            fill="#FAFAFA"
          />
          <path
            d="M154.129 165.496H146.121V159.584H154.129V165.496ZM153.917 193.277H146.333V168.962H153.917V193.277Z"
            fill="#FAFAFA"
          />
          <path
            d="M182.527 193.278H174.894V181.218C174.894 180.239 174.845 179.252 174.772 178.282C174.743 177.545 174.594 176.818 174.331 176.129C174.102 175.618 173.704 175.201 173.205 174.947C172.58 174.687 171.905 174.567 171.229 174.596C170.585 174.601 169.947 174.717 169.343 174.939C168.62 175.214 167.936 175.584 167.31 176.04V193.278H159.727V168.962H167.31V171.645C168.417 170.684 169.637 169.863 170.943 169.198C172.154 168.595 173.492 168.29 174.845 168.31C175.914 168.24 176.984 168.416 177.975 168.823C178.965 169.23 179.849 169.858 180.56 170.658C181.874 172.224 182.535 174.499 182.535 177.467L182.527 193.278Z"
            fill="#FAFAFA"
          />
        </svg>
      </div>
    </div>
  );
};

export default Timer3;
