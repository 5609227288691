import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { PublisherType, getPublisherList } from "../../axios";

import "./index.scss";

const TwelveSection = () => {
  const [clientList, setClientList] = useState<PublisherType[]>();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await getPublisherList(78);
      setClientList(response?.publishers ?? []);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="twelve-section flex flex-col justify-center">
      <img src="/image/background-2.png" id="background" />

      <div className="content-container">
        <div className="title-section">
          <Fade cascade direction="up" triggerOnce>
            <h1>
              <b>อีบุ๊กคุณภาพ ถูกลิขสิทธิ์</b>
            </h1>
            <h1>จากทั้งนักเขียนอิสระและสํานักพิมพ์มาให้เลือกมากที่สุด</h1>
            <h2>
              E-book, E-Textbook, E-magazine, E-newsPaper อัพเดตใหม่และขายดี
            </h2>
          </Fade>
        </div>
        <div className="flex justify-center">
          {clientList ? (
            <div className="company-grid">
              {clientList.map((el, index) => (
                <Fade
                  delay={300 + index * 10}
                  direction="up"
                  triggerOnce
                  key={el.uid}
                >
                  <div className="cover-image">
                    <Tooltip title={el.name}>
                      <img src={el.profileImage} />
                    </Tooltip>
                  </div>
                </Fade>
              ))}
            </div>
          ) : (
            <img
              src="/image/twelve-section/company-list.png"
              className="icon-image"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default TwelveSection;
