import { Button, Input, message, Modal, Space } from "antd";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { RegisterFormType, saveRegister, AxiosResponse } from "../../axios";
import "./index.scss";

interface RegisterModalProps {
  visible: boolean;
  onClose: () => void;
}

const RegisterModal = (props: RegisterModalProps) => {
  const [loading, setLoading] = useState(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const characterRegExp = /^[\u0E00-\u0E7Fa-zA-Z0-9]/g;

  const initialValues: RegisterFormType = {
    firstName: "",
    lastName: "",
    postPosition: "",
    organization: "",
    email: "",
    phoneNumber: "",
    // exhibitorNumber: 1,
    // attendanceType: AttendanceType.Online,
  };

  const validate = Yup.object({
    firstName: Yup.string()
      .min(2, "ชื่อมีต้องมากกว่า 2 ตัวอักษร")
      .required("กรุณากรอกชื่อ"),
    lastName: Yup.string()
      .min(2, "นามสกุลมีต้องมากกว่า 2 ตัวอักษร")
      .required("กรุณากรอกนามสกุล"),
    postPosition: Yup.string()
      .matches(characterRegExp, "กรุณากรอกตำแหน่งให้ถูกต้อง")
      .required("กรุณากรอกตำแหน่ง"),
    organization: Yup.string()
      .matches(characterRegExp, "กรุณากรอกหน่วยงานให้ถูกต้อง")
      .required("กรุณากรอกหน่วยงาน"),
    email: Yup.string().email("อีเมลไม่ถูกต้อง").required("กรุณากรอกอีเมล"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "เบอร์โทรศัพท์ไม่ถูกต้อง")
      .required("กรุณากรอกเบอร์โทรศัพท์")
      .min(9, "เบอร์โทรศัพท์ต้องมี 9 หลัก")
      .max(10, "เบอร์โทรศัพท์ต้องมีไม่เกิน 10 หลัก"),
  });

  const registerForm = async (value: RegisterFormType) => {
    try {
      setLoading(true);
      const response = await saveRegister(value);
      if (response) {
        setLoading(false);
        message.success("คุณได้ลงทะเบียนเรียบร้อยแล้ว!");
        props.onClose();
        formik.resetForm();
      }
    } catch (error) {
      const err = error as AxiosError;
      message.error(`${(err.response?.data as AxiosResponse).message}`);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validate,
    onSubmit: (values) => {
      const data: RegisterFormType = {
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        postPosition: values.postPosition?.trim(),
        organization: values.organization?.trim(),
        email: values.email?.toLocaleLowerCase().trim(),
        phoneNumber: values.phoneNumber?.trim(),
        // exhibitorNumber: values.exhibitorNumber,
        // attendanceType: values.attendanceType,
      };
      registerForm(data);
    },
  });

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      title={null}
      footer={null}
      style={{ top: 10 }}
      className="register-modal"
    >
      <h1 className="header">ลงทะเบียนขอรับเอกสาร</h1>
      <form onSubmit={formik.handleSubmit}>
        <Space size={16} direction="vertical">
          <div>
            <label>ชื่อ</label>
            <Input
              id="firstName"
              name="firstName"
              placeholder="กรอกชื่อ"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error-text">{formik.errors.firstName}</div>
            ) : null}
          </div>

          <div>
            <label>นามสกุล</label>
            <Input
              id="lastName"
              name="lastName"
              placeholder="กรอกนามสกุล"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error-text">{formik.errors.lastName}</div>
            ) : null}
          </div>

          <div>
            <label>ตำแหน่ง</label>
            <Input
              id="postPosition"
              name="postPosition"
              placeholder="กรอกดำแหน่ง"
              onChange={formik.handleChange}
              value={formik.values.postPosition}
            />
            {formik.touched.postPosition && formik.errors.postPosition ? (
              <div className="error-text">{formik.errors.postPosition}</div>
            ) : null}
          </div>

          <div>
            <label>หน่วยงาน</label>
            <Input
              id="organization"
              name="organization"
              placeholder="กรอกหน่วยงาน"
              onChange={formik.handleChange}
              value={formik.values.organization}
            />
            {formik.touched.organization && formik.errors.organization ? (
              <div className="error-text">{formik.errors.organization}</div>
            ) : null}
          </div>

          <div>
            <label>อีเมล</label>
            <Input
              id="email"
              name="email"
              placeholder="กรอกอีเมล"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-text">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <label>เบอร์โทร</label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              placeholder="กรอกเบอร์โทร"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="error-text">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>
          {/* 
					<div>
						<div className='margin-bottom-4'>จำนวนผู้เข้าร่วมงาน</div>
						<Radio.Group
							id='exhibitorNumber'
							name='exhibitorNumber'
							value={formik.values.exhibitorNumber}
							onChange={formik.handleChange}
						>
							<Radio value={1}>1</Radio>
							<Radio value={2}>2</Radio>
						</Radio.Group>
					</div>

					<div>
						<div className='margin-bottom-4'>รูปแบบการร่วมงาน</div>
						<Radio.Group
							id='attendanceType'
							name='attendanceType'
							value={formik.values.attendanceType}
							onChange={formik.handleChange}
							size='large'
						>
							<Radio value={AttendanceType.Online}>Online</Radio>
							<Radio value={AttendanceType.Onsite}>On Site</Radio>
						</Radio.Group>
					</div> */}
        </Space>

        {/* {formik.values.attendanceType === AttendanceType.Onsite && (
					<div className='detail'>
						<span>สถานที่จัดงาน</span>
						<span>วันที่ศุกร์ที่ 10 พฤษภาคม 2565 เวลา 13.30 - 15.30 น.</span>
						<span>
							ณ อุทยานการเรียนรู้ TK Park ห้องศูนย์การเรียนรู้อเนกประสงค์ <br />
							ชั้น 8 ศูณย์การค้าเซ็นทรัลเวิลด์
						</span>
					</div>
				)}

				{formik.values.attendanceType === AttendanceType.Online && (
					<div className='detail'>
						<span>ฟังทอล์คออนไลน์</span>
						<span>วันที่ศุกร์ที่ 10 มิถุนายน 2565 เวลา 13.30 - 15.30 น.</span>
						<span>
							ถ่ายทอดสดผ่านทาง Live Stream <br />
							ฟังทอล์คออนไลน์ ทางแอปพลิเคชัน Meet <br />
							ส่งรายละเอียดการเข้าร่วมงานทางอีเมลที่ลงทะเบียน
						</span>
					</div>
				)} */}

        <div className="button-wrapper">
          <Button shape="round" loading={loading} htmlType="submit">
            ลงทะเบียน
          </Button>
        </div>

        <a href="/privacy-policy" target="_blank">
          นโยบายความเป็นส่วนตัว
        </a>
      </form>
    </Modal>
  );
};

export default RegisterModal;
