import { Space } from "antd";
import { Bounce, Fade } from "react-awesome-reveal";
import "./index.scss";

const SevenSection = () => {
  return (
    <section className="seven-section flex flex-col align-center justify-center">
      <img src="/image/seven-section/background.png" id="background" />

      <div className="content-container flex flex-col align-center justify-center">
        <div className="title-section">
          <Fade direction="up" cascade>
            <h1>ระบบจัดการสิทธิ์ทรัพยากร</h1>
            <h1 className="margin-bottom-0">
              <b>การยืม - คืน - จองคิว</b> ที่มีประสิทธิภาพ
            </h1>
          </Fade>
        </div>

        <div className="flex  align-center col-gap">
          <div className="left-col">
            <Space size={24}>
              <Bounce delay={1000}>
                <div className="flex flex-col justify-center cover-image">
                  <img src="/image/seven-section/rent-mobile.png" />
                  <span>การยืม</span>
                </div>
              </Bounce>
              <Bounce delay={1100}>
                <div className="flex flex-col justify-center  cover-image">
                  <img src="/image/seven-section/return-mobile.png" />
                  <span>การคืน</span>
                </div>
              </Bounce>
            </Space>
          </div>
          <div className="right-col flex flex-col justify-center align-center">
            <Space size={5}>
              <Bounce delay={1200}>
                <div className="cover-image flex justify-center">
                  <img
                    src="/image/seven-section/notification-icon.png"
                    className="notification-icon"
                  />

                  <img src="/image/seven-section/notification-1.png" />
                </div>
              </Bounce>
              <Bounce delay={1300}>
                <div className="cover-image flex justify-center">
                  <img src="/image/seven-section/notification-2.png" />
                </div>
              </Bounce>
            </Space>
            <Bounce delay={1300} style={{ marginTop: "10px" }}>
              <span>ระบบแจ้งเตือนเมื่อถึงคิว</span>
            </Bounce>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SevenSection;
