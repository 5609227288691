import { Skeleton, Space, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ClientResponse, getClientList } from '../../axios'
import { Pagination } from 'swiper'
import BackgroundAnimation1 from './background/background-1'
import BackgroundAnimation2 from './background/background-2'
import './index.scss'

enum SwiperSection {
   governmentLibrary,
   company,
   school
}

const FourteenSection = () => {
   const [clientList, setClientList] = useState<ClientResponse>()

   useEffect(() => {
      fetchClientList()
   }, [])

   const fetchClientList = async () => {
      try {
         const response = await getClientList(1000)
         setClientList(response)
      } catch (error) {
         console.log(error)
      }
   }

   const renderSwiperSlide = (section: SwiperSection) => {
      if (clientList) {
         switch (section) {
            case SwiperSection.governmentLibrary:
               return clientList.governmentLibrary.map((el) => (
                  <SwiperSlide key={el.clientUID} className="cover-image">
                     <a href={el.redirectURL} target="_blank">
                        <Tooltip title={el.clientName}>
                           <img src={el.logoImages} />
                        </Tooltip>
                     </a>
                  </SwiperSlide>
               ))
            case SwiperSection.company:
               return clientList.company.map((el) => (
                  <SwiperSlide key={el.clientUID} className="cover-image">
                     <Tooltip title={el.clientName}>
                        <img src={el.logoImages} />
                     </Tooltip>
                  </SwiperSlide>
               ))
            case SwiperSection.school:
               return clientList.school.map((el) => (
                  <SwiperSlide key={el.clientUID} className="cover-image">
                     <Tooltip title={el.clientName}>
                        <img src={el.logoImages} />
                     </Tooltip>
                  </SwiperSlide>
               ))
         }
      } else {
         return Array.from(Array(10).keys()).map((el) => (
            <SwiperSlide key={el} className="skeleton-item">
               <Skeleton.Image />
            </SwiperSlide>
         ))
      }
   }

   return (
      <section className="fourteen-section">
         <div className="company-section flex flex-col justify-center">
            <img src="/image/background-2.png" id="background" />

            <div className="content-container">
               <div className="title-section">
                  <Fade cascade direction="up" triggerOnce>
                     <h1 className="main-title margin-bottom-16">
                        ให้บริการมากกว่า 100 องค์กร
                     </h1>
                     <p className="customer-details">
                        Hibrary ได้รับความไว้วางใจองค์กรต่าง ๆ
                        ทั้งหน่วยงานของรัฐและเอกชน โรงเรียน มหาวิทยาลัย <br />
                        และยังเป็นตัวช่วยในการส่งเสริมการเรียนรู้ในชุมชุน
                        <br />
                        ผ่านห้องสมุดประชาชน 39 อำเภอ
                        ครอบคลุมในทุกภาคของประเทศไทย
                     </p>
                  </Fade>
               </div>

               <Space size={32} direction="vertical" align="center">
                  <div>
                     <Fade direction="up" cascade triggerOnce>
                        <h1 className="sub-title">
                           ห้องสมุดประชาชน{' '}
                           {clientList?.governmentLibrary.length ?? 0} แห่ง
                        </h1>
                        <Swiper
                           spaceBetween={16}
                           slidesPerView={14}
                           freeMode={true}
                           pagination={{
                              dynamicBullets: true
                           }}
                           modules={[Pagination]}
                        >
                           {renderSwiperSlide(SwiperSection.governmentLibrary)}
                        </Swiper>
                     </Fade>
                  </div>
                  <div>
                     <Fade direction="up" cascade triggerOnce>
                        <h1 className="sub-title">องค์กรภาครัฐและเอกชน</h1>
                        <Swiper
                           spaceBetween={16}
                           slidesPerView={14}
                           freeMode={true}
                           pagination={{
                              dynamicBullets: true
                           }}
                           modules={[Pagination]}
                        >
                           {renderSwiperSlide(SwiperSection.company)}
                        </Swiper>
                     </Fade>
                  </div>
                  <div>
                     <Fade
                        direction="up"
                        cascade
                        triggerOnce
                        className="flex flex-col align-center"
                     >
                        <h1 className="sub-title">โรงเรียนและมหาวิทยาลัย</h1>
                        <Swiper
                           spaceBetween={16}
                           slidesPerView={14}
                           freeMode={true}
                           pagination={{
                              dynamicBullets: true
                           }}
                           modules={[Pagination]}
                        >
                           {renderSwiperSlide(SwiperSection.school)}
                        </Swiper>
                     </Fade>
                  </div>
               </Space>
            </div>
         </div>

         <BackgroundAnimation1 />

         <div className="concept-section flex flex-col justify-center">
            <img
               src="/image/fourteen-section/background-3.png"
               id="background"
            />
            <Space size={48} direction="vertical" className="content-container">
               <Fade direction="up" cascade damping={0.3}>
                  <h1 className="main-title margin-bottom-0">
                     “เราอยากให้ห้องสมุดเข้าถึงได้ง่ายที่สุด
                  </h1>
                  <h1 className="main-title margin-bottom-16">
                     มีทรัพยากรที่ตอบโจทย์ที่สุด สนันสนุนอีบุ๊กถูกลิขสิทธิ์”
                  </h1>
               </Fade>

               <h1 className="main-title">
                  <Fade direction="up" cascade damping={0.3}>
                     <p>
                        {' '}
                        ปลดล็อกข้อจำกัดต่าง ๆ เพื่อการเรียนรู้ไม่มีที่สิ้นสุด
                        ด้วย
                     </p>
                     <b>Hibrary ระบบห้องสมุดออนไลน์ที่สมบูรณ์ที่สุด</b>
                  </Fade>
               </h1>
            </Space>
         </div>

         <BackgroundAnimation2 />
      </section>
   )
}

export default FourteenSection
