import { useEffect, useState } from "react";
import { Bounce, Fade, Rotate, Zoom } from "react-awesome-reveal";
import "./index.scss";

const NineSection = () => {
  const [view, setView] = useState<boolean>(false);
  useEffect(() => {
    const element = document.getElementById("type-anim");

    if (view) {
      element!.classList.add("anim-typewriter");
    } else {
      element!.classList.remove("anim-typewriter");
    }
  }, [view]);

  return (
    <section className="section flex flex-col">
      <img src="/image/nine-section/background.png" id="background" />
      <div className="nine-section">
        <div className="content-container">
          <div className="title-section">
            <Fade cascade direction="up">
              <h1>
                รองรับการใช้งานแบบ Walk in โดยการ<b>สแกน QR Code</b>
              </h1>
              <h2>
                เพื่อเข้าใช้งานได้สะดวก
                สําหรับการให้บริการในห้องสมุดหรือสถานที่ต่างๆ
              </h2>
            </Fade>
          </div>
          <div className="flex gap-8">
            <div className="flex flex-col gap-8">
              <Zoom delay={800}>
                <div className="image-cover">
                  <img src="/image/nine-section/image-1.png" />
                </div>
              </Zoom>
              <div className="flex gap-8">
                <Zoom delay={1100}>
                  <div className="image-cover">
                    <img src="/image/nine-section/image-2.png" />
                  </div>
                </Zoom>
                <Zoom delay={1200}>
                  <div className="image-cover">
                    <img src="/image/nine-section/image-3.png" />
                  </div>
                </Zoom>
              </div>
            </div>
            <Zoom delay={1300}>
              <div className="image-cover">
                <img src="/image/nine-section/image-4.png" />
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      <div className="ten-section flex flex-col align-center">
        <div className="content-container">
          <div className="title-section">
            <Fade
              cascade
              direction="up"
              onVisibilityChange={(inView: boolean) => setView(inView)}
            >
              <h1>
                ระบบการ<b>ค้นหาห้องสมุดที่อยู่ในพื้นที่ให้บริการ</b>
              </h1>
              <h2>สามารถสมัครสมาชิกห้องสมุดง่ายๆ ผ่านแอปพลิเคชัน</h2>
            </Fade>
          </div>
          <div className="flex align-center">
            <div className="image-cover">
              <img src="/image/nine-section/search.svg" className="search" />
              <div className="type-writer">
                <p className="line-1" id="type-anim">
                  ห้องสมุดประชาชน
                </p>
              </div>
            </div>
            <div
              className="image-cover"
              style={{ transform: "translateY(30px)" }}
            >
              <Rotate direction="bottom-left" style={{ overflow: "hidden" }}>
                <img src="/image/nine-section/hand.png" className="hand" />
              </Rotate>
              <div className="comment">
                <Bounce delay={1300}>
                  <img src="/image/nine-section/comment.svg" />
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NineSection;
