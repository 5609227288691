/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, message, Spin } from 'antd'
import {
   Device,
   ErrorResponse,
   SignInSsoBody,
   signInWithSso,
   Stage
} from '../../axios'
import { isMobile } from 'react-device-detect'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import ManageDevice from '../manage-device/manageDevice'
const RedirectSso = () => {
   const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />
   const search = window.location.hash.substring(1)
   const params = new URLSearchParams(search)
   const code = params.get('code')
   const clientInfo = params.get('client_info')
   const accessToken = params.get('access_token')
   // const stage = params.get("stage");
   const { stage } = useParams()

   const [timer, setTimer] = useState(5)
   const [isSignIn, setIsSignIn] = useState(false)
   const [isError, setIsError] = useState(false)
   const [disable, setDisable] = useState(true)
   const [devices, setDevices] = useState<{
      devices: Device[]
      memberUID: string
   }>()

   useEffect(() => {
      const interval = () =>
         setTimeout(() => {
            if (timer > 0) setTimer(timer - 1)
            else if (timer === 0 && !isSignIn && !isMobile) {
               signInSso()
            }
         }, 1000)

      setTimeout(() => {
         setDisable(false)
         interval()
      }, 1000)
   }, [timer])

   const signInSso = async () => {
      try {
         setIsSignIn(true)
         let body: SignInSsoBody = {}
         if (clientInfo !== null) Object.assign(body, { clientInfo })
         if (code !== null) Object.assign(body, { code })
         if (accessToken !== null) Object.assign(body, { accessToken })
            
         if (stage !== undefined && stage !== null && !isMobile) {
            console.log('true ');
            
            let res = await signInWithSso(body, stage as Stage)
            if (res.data && res.status) {
               setTimeout(() => {
                  window.location.href = res.data!.homepageUrl
               }, 500)
            }
         }
      } catch (error: unknown) {
         setIsError(true)
         const e = error as AxiosError<ErrorResponse>
         if (e!.response!.data.errorCode === '004') {
            setDevices({
               devices: e.response?.data.data?.devices || [],
               memberUID: e.response?.data.data?.memberUID || ''
            })
         } else {
            setTimeout(() => {
               // history.back()
            }, 300)
         }
         message.error(e!.response!.data.message)
      }
   }

   const checkError = () => {
      if (
         ((code !== null &&
            code !== undefined &&
            clientInfo !== null &&
            clientInfo !== undefined) ||
            (accessToken !== undefined && accessToken !== null)) &&
         !isError
      ) {
         return true
      } else {
         return false
      }
   }

   return (
      <div className="redirect-sso">
         <div className="counting-down-section">
            {devices ? (
               <></>
            ) : (
               <>
                  <h2>
                     {checkError()
                        ? 'กำลังเข้าสู่ระบบ'
                        : 'พบข้อผิดพลาด กรุณาลองอีกครั้ง'}
                  </h2>
                  <div style={{ position: 'relative' }}>
                     <Spin indicator={antIcon} size={'large'} />
                  </div>
               </>
            )}

            {checkError() && !isMobile && !disable ? (
               <Button
                  className="continue-button"
                  onClick={() => {
                     signInSso()
                  }}
               >
                  {isSignIn ? `กำลังดำเนินการ...` : `เข้าสู่ระบบ (${timer})`}
               </Button>
            ) : null}
         </div>

         <ManageDevice
            open={devices !== undefined}
            onClose={() => setDevices(undefined)}
            devices={devices?.devices ?? []}
            memberUID={devices?.memberUID ?? ''}
         />
      </div>
   )
}

export default RedirectSso
