import axios, { AxiosError } from 'axios'
import J from '../../../package.json'
import {
   osVersion,
   osName,
   mobileModel,
   browserName
} from 'react-device-detect'

export enum AttendanceType {
   Online = 'online',
   Onsite = 'onsite'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AxiosResponse<T = any> {
   status: boolean
   data?: T
   errorCode?: string
   message?: string
}

export interface PublisherResponse {
   publishers: PublisherType[]
}

export interface PublisherType {
   uid: string
   name: string
   profileImage: string
}

export interface ClientResponse {
   governmentLibrary: GovernmentLibraryType[]
   company: CompanyType[]
   school: SchoolType[]
}

export interface GovernmentLibraryType {
   clientUID: string
   clientName: string
   companyName: string
   companyPrefix: string
   displayName: string
   logoImages: string
   redirectURL: string
}

export interface CompanyType {
   clientUID: string
   clientName: string
   companyName: string
   companyPrefix: string
   displayName: string
   logoImages: string
}

export interface SchoolType {
   clientUID: string
   clientName: string
   companyName: string
   companyPrefix: string
   displayName: string
   logoImages: string
}

export const appInfo = {
   deviceuid: '',
   appname: '',
   appid: '',
   appversion: '',
   os: '',
   osversion: '',
   devicename: '',
   devicemodel: ''
}

export interface RegisterFormType {
   firstName: string | undefined
   lastName: string | undefined
   postPosition: string | undefined
   organization: string | undefined
   email: string | undefined
   phoneNumber: string | undefined
   // exhibitorNumber: number;
   // attendanceType: AttendanceType;
}

export interface SignInSsoResponse {
   client: DataClient
   clients: Clients
   homepageUrl: string
   userToken: string
}

export interface DataClient {
   themes: Themes
}

export interface Device {
   appname: string
   os: string
   appid: string
   appversion: string
   devicename: string
   devicetoken: string
   deviceuid: string
   osversion: string
   devicemodel: string
   regisDate: number
   isCurrentDevice: boolean
   loginDate?: number
   notificationToken?: string
}

export interface Themes {
   Background: string
   Body: string
   Error: string
   OnBackground: string
   OnError: string
   OnPrimary: string
   OnSecondary: string
   OnSurface: string
   Primary: string
   PrimaryVariant: string
   Secondary: string
   SecondaryVariant: string
   Surface: string
   Title: string
}

export interface Clients {
   clientUID: string
   clients: ClientElement[]
   expireDate: number
   memberType: string
   startDate: number
}

export interface ClientElement {
   booking: number
   clientName: string
   clientUID: string
   companyPrefix: string
   displayName: string
   expireDate: number
   isActive: number
   isCurrentClient: number
   license: number
   logoImages: string
   memberType: string
   status: number
   subLibrary: string
}

export interface ErrorResponse<T = any> {
   message: string
   errorCode: string
   status: boolean
   data?: T
}

export interface SignInSsoBody {
   code?: string
   clientInfo?: string
   accessToken?: string
}

export enum Stage {
   development = 'development',
   production = 'production',
   staging = 'staging'
}

export const getPublisherList = async (limit?: number) => {
   const path = `${process.env.REACT_APP_API_ENDPOINT}/public/publisher/Hibrary`
   const {
      data: { data }
   } = await axios.get<AxiosResponse<PublisherResponse>>(path, {
      params: { limit: limit ?? 65 },
      headers: appInfo
   })
   return data
}

export const getClientList = async (limit?: number) => {
   const path = `${process.env.REACT_APP_API_ENDPOINT}/public/client/Hibrary`
   const {
      data: { data }
   } = await axios.get<AxiosResponse<ClientResponse>>(path, {
      params: { limit: limit ?? 30 },
      headers: appInfo
   })
   return data
}

export const saveRegister = async (register: RegisterFormType) => {
   const path = `${process.env.REACT_APP_FORM_API_ENDPOINT}/application/form/register`
   const bodyReq = {
      register: register
   }
   const data = axios.post<AxiosResponse>(path, bodyReq, { headers: appInfo })
   return data
}

export interface HtiResponse<T = any> {
   status: boolean
   data?: T
   errorCode?: string
   message?: string
}

export interface ListDevicesResponse {
   devices: Device[]
}

const getData = async () => {
   try {
      const res = await axios.get('https://api.ipify.org?format=json')
      return res.data.ip + ''
   } catch (error) {
      return ''
   }
}

const getApiPath = (stage: Stage) => {
   switch (stage) {
      case Stage.development:
         return 'https://api-elibrary-dev.hytexts.com/v5'
      case Stage.production:
         return 'https://api-elibrary-prod.hytexts.com/v5'
      case Stage.staging:
         return 'https://api-elibrary-staging.hytexts.com/v5'

      default:
         return undefined
   }
}

export const signInWithSso = async (body: SignInSsoBody, stage: Stage) => {
   try {
      const headers = {
         appversion: J.version,
         appname: process.env.REACT_APP_NAME ?? '',
         appid: process.env.REACT_APP_HIBRARY_APP_ID ?? '',
         deviceuid: 'hibrary.LANDING.' + (await getData()),
         devicemodel: mobileModel === 'none' ? browserName : mobileModel,
         devicename: osName,
         osversion: osVersion,
         os: osName,
         'x-accept-version': process.env.REACT_APP_API_VERSION ?? ''
      }
      // console.log(body)
      console.log('after header ')
      const apiPath = getApiPath(stage)
      console.log('api path', apiPath)

      if (apiPath) {
         const { data } = await axios.post<AxiosResponse<SignInSsoResponse>>(
            `${apiPath}/signin/sso`,
            body,
            { headers }
         )
         return data
      } else {
         return Promise.reject()
      }
   } catch (error) {
      let e = error as AxiosError<ErrorResponse>
      console.log(e)

      return Promise.reject(error)
   }
}

export const removeDeviceList = async (
   deviceToken: string,
   stage: Stage,
   memberUID?: string
) => {
   const headers = {
      appversion: J.version,
      appname: process.env.REACT_APP_NAME ?? '',
      appid: process.env.REACT_APP_HIBRARY_APP_ID ?? '',
      deviceuid: 'hibrary.LANDING.' + (await getData()),
      devicemodel: mobileModel === 'none' ? browserName : mobileModel,
      devicename: osName,
      osversion: osVersion,
      os: osName,
      'x-accept-version': process.env.REACT_APP_API_VERSION ?? ''
   }
   const apiPath = getApiPath(stage)

   if (memberUID) {
      const { data } = await axios.delete<HtiResponse<ListDevicesResponse>>(
         `${apiPath}/signin/devices`,
         {
            data: { deviceToken, memberUID },
            headers
         }
      )
      return data
   } else {
      const { data } = await axios.delete<HtiResponse<ListDevicesResponse>>(
         `${apiPath}/member/devices`,
         {
            data: { deviceToken },
            headers
         }
      )
      return data
   }
}
