import React from "react";
import "./index.scss";

const BackgroundAnimation2 = () => {
  return (
    <div className="background-container">
      <div className="background-cover">
        <div className="image-container">
          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-1"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-2.png"
            id="background-row-2"
            className="anim-slide-2"
          />

          <img
            src="/image/fourteen-section/background-mobile-3.png"
            id="background-row-3"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-4.png"
            id="background-row-4"
            className="anim-slide-2"
          />

          <img
            src="/image/fourteen-section/background-mobile-5.png"
            id="background-row-5"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-6"
            className="anim-slide-2"
          />

          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-7"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-8"
            className="anim-slide-1"
          />
        </div>
      </div>
    </div>
  );
};

export default BackgroundAnimation2;
