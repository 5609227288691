import { Bounce, Fade } from "react-awesome-reveal";
import "./index.scss";

const FourSection = () => {
  return (
    <section className="four-section flex justify-center align-center">
      <div className="content-container">
        <div className="title-section">
          <Fade cascade direction="up">
            <h1>
              เรียนรู้ได้อย่างไม่จำกัดด้วย<b>อุปกรณ์ที่หลากหลาย</b>
            </h1>
            <h2>รองรับการใช้งานทั้งในระบบ iOS Andriod และเว็บบราวเซอร์</h2>
            <h2>
              <span>ไม่มีค่าระบบ หรือ บํารุงรักษาระบบรายปี </span>
              พร้อมรองรับระบบปฎิบัติการณ์ล่าสุดเสมอ
            </h2>
          </Fade>
        </div>

        <div className="image-cover">
          <img src="/image/four-section/web-image.png" />
          <div id="ios-tag">
            <Bounce delay={1700}>
              <img src="/image/four-section/ios-tag.svg" />
            </Bounce>
          </div>

          <div id="android-tag">
            <Bounce delay={1400}>
              <img src="/image/four-section/android-tag.svg" />
            </Bounce>
          </div>

          <div id="web-tag">
            <Bounce delay={1100}>
              <img src="/image/four-section/web-browser-tag.svg" />
            </Bounce>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FourSection;
