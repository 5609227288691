import { Fade } from "react-awesome-reveal";
import "./index.scss";

const ElevenSection = () => {
  return (
    <section className="eleven-section flex flex-col justify-center">
      <div className="title-section">
        <Fade cascade direction="up">
          <h1>
            <b>บริการ Cloud Storage</b> สําหรับเผยแพร่ทรัพยากรขององค์กร
          </h1>
          <h2>รองรับ อีบุ๊ก , วิดีโอ, หนังสือเสียง และ พ็อดคาสท์</h2>
        </Fade>
      </div>
      <div className="content-container flex justify-center">
        <img src="/image/ten-section/artboard-1.png" />
      </div>
    </section>
  );
};

export default ElevenSection;
