import React from "react";
import "./index.scss";

const BackgroundAnimation1 = () => {
  return (
    <div className="background-container-1">
      <div className="background-cover flex">
        <div className="logo-container flex align-center ">
          <img src="/image/fourteen-section/hibrary-logo.png" />
        </div>
        <div className="image-container">
          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-1"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-2.png"
            id="background-row-2"
            className="anim-slide-2"
          />

          <img
            src="/image/fourteen-section/background-mobile-3.png"
            id="background-row-3"
            className="anim-slide-1"
          />

          <img
            src="/image/fourteen-section/background-mobile-1.png"
            id="background-row-4"
            className="anim-slide-2"
          />
        </div>
      </div>
    </div>
  );
};

export default BackgroundAnimation1;
