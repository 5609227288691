import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const SSOSignOut = () => {
   const { instance } = useMsal()
   const [params] = useSearchParams()
   const pathUrl = params.get('redirect')

   useEffect(() => {
      const request = setTimeout(() => signOutSSO(), 1000)

      return () => {
         clearInterval(request)
      }
   }, [])

   const signOutSSO = () => {
      instance.logoutRedirect({
         postLogoutRedirectUri: `/redirect?redirect=${pathUrl}`
      })
   }

   return <></>
}

export default SSOSignOut
