import { Modal } from "antd";
import "./index.scss";

interface ContactModalProps {
  visible: boolean;
  onClose: () => void;
}

const ContactModal = (props: ContactModalProps) => {
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      className="contact-modal"
    >
      <div className="modal-container">
        <img
          src="/image/modal/contact-cover.svg"
          alt="contact-cover"
          className="cover-image"
        />

        <h2 className="header">ติดต่อเรา</h2>

        <div>
          <h2 className="detail">
            Email : <b>contact@hytexts.com</b>
          </h2>

          <h2 className="detail">
            Customer Service : <b>089-134-7470</b>
          </h2>

          <h2 className="detail">
            Customer Service : <b>02-024-6690</b>
          </h2>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
