import React from 'react'
import ReactDOM from 'react-dom'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'antd/dist/antd.variable.min.css'
import './css/index.scss'

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
   <React.StrictMode>
      <MsalProvider instance={msalInstance}>
         <App />
      </MsalProvider>
   </React.StrictMode>,
   document.getElementById('root')
)

reportWebVitals()
