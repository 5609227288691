import "./index.scss";

interface RegisterFieldProps {
	onOpen: () => void;
}

const RegisterField = (props: RegisterFieldProps) => {
	return (
		<div className='register-field'>
			<img
				src='/image/register-background.png'
				id='background'
				style={{ zIndex: -1 }}
			/>
			<img
				src='/image/register-text-image.svg'
				id='background'
				className='text-background'
				onClick={props.onOpen}
			/>
		</div>
	);
};

export default RegisterField;
